// Cards
//
// Cards have headers and contents (like lists of items / and or forms).  Headers can also be expand / collapsable
//
// markup:
// <div ng-module="onelifeUi.shared">
//  <om-card>
//    <om-card-title>
//      <om-title heading="Card Title">
//        <button class="om-button -invisible -right-align om-title-icon icon-push-to-note"></button>
//      </om-title>
//    </om-card-title>
//    <div class="om-list" om-card-content>
//      <div class="om-item">Item 1</div>
//      <div class="om-item">Item 2</div>
//    </div>
//  </om-card>
// </div>
//
// Styleguide 3.5

.om-card {
  display: block;
  margin: $layout-gutter-width 0;
  border: 1px solid $gray2;

  [om-card-content] {
    background: $card-background-color;
  }

  .om-card-content {
    background: $card-background-color;
  }
}

.om-card-title {
  font-family: inherit;

  .om-title {
    background-color: $card-title-background-color;
    color: $card-title-color;
    font-family: inherit;

    &-text {
      margin-bottom: 0;
      padding: $global-spacing ($global-spacing * 2);
      text-shadow: $primary-heading-shadow;
    }

    button {
      padding: $global-spacing;
      min-width: unset;

      &:hover {
        background-color: $card-title-hover-background-color;
      }
    }

    .om-icon {
      background-color: $white;
      width: $card-title-icon-width;
      height: $card-title-icon-height;
    }
  }

  &.om-collapse-expanded > .om-expanded {
    @extend %expanded-content;
  }
}
