@import './marked';

om-rich-text-editor {
  display: block;

  ng-quill-editor {
    @extend %reset-text-transform;
    display: block;

    .ql-editor {
      // Must be tall enough on an empty thread to display all message template typeahead results
      min-height: rem-calc(168);
      font-size: 14px;
    }

    .ql-container.ql-snow {
      border-top: 0;
      border-color: $gray2;
    }

    .ql-toolbar.ql-snow {
      border-color: $gray2;
    }
  }
}
