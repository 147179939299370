// Drawer
//
// Takes any number of children (such as buttons) that will be treated as flex items.
// Flex items will be spaced out with the space-between value on the flex axis and center on the cross axis
//
// Markup:
//  For a drawer with 3 buttons (two of them being primary and secondary actions and a third flush to the right).
// <div ng-module="onelifeUi.shared">
//  <om-drawer>
//   <div> <!--- this is to have the two buttons treated as a single flex child -->
//     <button class="om-button -primary">Primary</button>
//     <button class="om-button -secondary" ng-click="drawerCtrl.open()">Show some hidden stuff</button>
//   </div>
//   <button class="om-button -link">Text Link Button</button>
//   <om-drawer-content>
//     Some hidden stuff
//     <button ng-click="drawerCtrl.close()">Hide me</button>
//   </om-drawer-content>
//  </om-drawer>
// </div>
//
// Styleguide 3.7

$drawer-border-color: $gray2;
$drawer-color: $gray1;

.om-drawer,
.om-drawer-content {
  border-top: 1px solid $drawer-border-color;
  background-color: $drawer-color;
  padding: $global-spacing;
}

.om-drawer {
  display: block;
  position: relative;
}

.om-drawer-content {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;

  &.ng-hide {
    top: 100%;
    opacity: 0;
  }

  &.ng-hide-remove.ng-hide-remove-active {
    transition: all .3s ease-in;
  }

  &.ng-hide-add.ng-hide-add-active {
    transition: all .3s ease-in;
  }
}
