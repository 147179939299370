// Button Groups
//
// Buttons that are visually grouped together.
//
// Markup:
// <div class="button-group" om-layout-fill>
//   <button type="button" class="om-button -secondary">2 Weeks</button>
//   <button type="button" class="om-button -secondary">1 Month</button>
//   <button type="button" class="om-button -secondary">3 Months</button>
//   <button type="button" class="om-button -secondary">6 Months</button>
//   <button type="button" class="om-button -secondary">1 Year</button>
// </div>
//
// Styleguide 3.12.4

// Buttons
//
// Used for submitting forms or firing click events in components.
//
// .-primary          - used for form submission/completion of tasks
// .-secondary        - auxiliary actions
// .-tertiary         - tertiary actions
// .-critical         - Use sparingly in cases where actions have dangerous or deadly actions
// .-invisible        - buttons with no background and automatic sizing. Use for card title icons.
// .-flat             - Buttons that look flat against their background. Use these along other buttons to maintain a consistent baseline and allow a large click surface area.
//
// Markup:
// <button type="button" class="om-button {{modifier_class}}">{{modifier_class}} Button</button>
// <br />
// <br />
// <button type="button" class="om-button {{modifier_class}}" disabled="disabled">Disabled {{modifier_class}} Button</button>
// <br />
// <br />
// <button type="button" class="om-button -processing {{modifier_class}}">{{modifier_class}} Processing...</button>
// <br />
// <br />
// <button type="button" class="om-button {{modifier_class}}" om-layout om-layout-align="space-between center" om-layout-gutter-mini><span class="om-icon icon-attachment"></span> <span>Iconified {{modifier_class}}</span></button>
//
// Styleguide 3.12

// Button Links
//
// Used for situations where you have a link next to a button and need it to have the same lineheight
//
// .-critical-link    - Use sparingly in cases where you need a button that looks like a link but need to assign a click action.
// .-link             - Buttons that look like links. Don't use button links if you expect the text to be very long. They don't wrap gracefully.
//
// Markup:
// <button type="button" class="om-button {{modifier_class}}">{{modifier_class}}</button>
// <br />
// <br />
// <button type="button" class="om-button {{modifier_class}}" disabled="disabled">Disabled {{modifier_class}}</button>
//
// Styleguide 3.12.1

.om-button {
  min-width: $button-min-width;
  height: $button-height;

  &.-primary {
    @extend %primary-button-style;
  }

  &.-secondary {
    @extend %secondary-button-style;
  }

  &.-tertiary {
    @extend %tertiary-button-style;
  }

  &.-critical {
    @extend %critical-button-style;
  }

  &.-invisible {
    background-color: inherit;
    width: auto;
    height: auto;
  }

  &.-right-align {
    text-align: right;
  }

  &.-slim {
    padding: 0 1em;
    line-height: 1.5em;
  }

  &.-flat {
    @extend %flat-button-style;
  }

  &.-flat-icon {
    @extend %flat-button-style;
    min-width: $large-icon-width;
    vertical-align: text-bottom;

    &:focus:not(:disabled):not(.-processing) {
      box-shadow: none;
      background-color: inherit;
    }

    &:hover:not(:disabled):not(.-processing) {
      box-shadow: none;
      background-color: darken($white, 15%);
    }
  }

  &.-link,
  &.-critical-link {
    @extend %om-link;
    width: auto;
    min-width: 0;
    line-height: $button-height;
    &.-inline {
      height: auto;
      line-height: 0;
    }
  }

  &.-critical-link {
    @extend %om-critical-link;

    &.-disabled {
      opacity: 0.4;
      cursor: not-allowed;
      text-decoration: none;
    }
  }

  &.original-case {
    text-transform: none;
  }
}

[type='checkbox']:focus {
  box-shadow: shadow-style(inset, black-shadow), shadow-style(focused, blue-shadow);
}

.button-group {
  display: flex;

  .om-button {
    flex-grow: 1;
    margin-right: 1px;
    border-radius: 0;

    &:first-child {
      border-radius: $button-border-radius 0 0 $button-border-radius;
    }

    &:last-child {
      margin-right: 0;
      border-radius: 0 $button-border-radius $button-border-radius 0;
    }
  }
}
