%comments {
  @extend %banner;
  margin: 0;
  border-radius: 0;
  background: $green1;
}

om-comments {
  @extend %comments;
  display: block;
}
