$slim-border-width: 2px;
$timeline-background-color: $card-background-color;
$max-timeline-height: calc(100vh - (#{$nav-height} + #{$info-bar-height}) - #{$tabs-height} - (#{$layout-gutter-width} * 2));

.timeline {
  background-color: $timeline-background-color;
  max-height: calc(#{$max-timeline-height} - 8px);

  .icon-large {
    width: 24px;
    height: 24px;
  }

  .fa-calendar {
    color: $blue4;
  }

  .-unread-task {
    background-color: $yellow1;
  }

  [om-timeline-divider],
  .om-timeline-divider {
    @extend %bold-type;
    background-color: $gray1;
    padding: $global-spacing $global-spacing * 2;

    &.-past {
      border-top: 1px solid $gray2;
      border-bottom: 1px solid $gray2;
    }
  }

  [om-scroll] {
    max-height: calc(#{$max-timeline-height} - #{$search-box-height} - #{$search-filter-height});
  }

  .om-item {
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);

    &.om-timeline-no-results {
      border-top: none;
    }

    .om-timeline-appointment-time {
      margin-right: 0;
      padding-right: $global-spacing;
      text-align: right;
      color: $gray4;
      font-size: 13px;
    }

    .om-timeline-appointment-date {
      color: $gray4;
      font-size: 13px;
    }

    .om-timeline-appointment-provider {
      color: $gray4;
      font-size: 13px;
    }

    .om-timeline-appointment-reason {
      color: $gray4;
      font-size: 13px;
      font-style: italic;
    }
  }

  om-transfer-timeline-item {
    display: flex;
  }

  om-attach-timeline-item {
    min-width: 0;
  }

  .timeline-name-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .timeline-draft-text {
    min-width: 0;
  }

  .timeline-message-count {
    flex: 0 0 auto;
  }
}

.attach-to-order-container {
  position: relative;
  min-width: 0;
}

.attach-to-order-text-container {
  position: relative;

  .timeline-item-author {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $gray4;
    font-size: 13px;
  }

  .timeline-item-date {
    color: $gray4;
    font-size: 13px;
  }
}

.attach-to-order-button-container {
  position: absolute;
  top: 0;
  height: 100%;
}

.attach-to-order-button.-flat {
  cursor: pointer;
}

.not-attachable-text {
  @extend %bold-type;
  color: $gray4;
}

.slim-border-bottom {
  border-bottom: $slim-border-width solid $blue1;
}

.subheader-text {
  font-size: $base-font-size * 1.5;
}

canvas {
  width: 100%;
}
