// Fade Out
//
// Hides extra content behind a fade-to-white cover.
// When hidden, only 5 lines of content are visible, with the fifth line partially obscured by the cover.
// Place on an om-collapsed so it can be expanded to display full content.
//
// markup:
// <div ng-modules="onelifeUi">
//   <om-collapse expand-by-default="false">
//     <om-expanded>
//       <p>Airways alignment astringents body type bronchoconstriction canine teeth carbohydrate cone diaphragm dislocation enuresis frenulum gluteus maximus hypoglycemia inhaler insulin injections involuntary muscle pancreas papillae peak flow meter puberty retina taste buds tendons tobacco triggers virus wisdom teeth zoonosis.
//          Blood bank bone marrow bronchial tubes carbohydrate diaphragm gastric juices glucagon hydrocortisone icu immune system nicotine night guard polyuria radiologist rx scoliosis sebum stapes ultrasound umbilical cord.
//       </p>
//     </om-expanded>
//     <om-collapsed om-fade-out>
//       <om-collapse-toggle>
//         <p>Airways alignment astringents body type bronchoconstriction canine teeth carbohydrate cone diaphragm dislocation enuresis frenulum gluteus maximus hypoglycemia inhaler insulin injections involuntary muscle pancreas papillae peak flow meter puberty retina taste buds tendons tobacco triggers virus wisdom teeth zoonosis.
//            Blood bank bone marrow bronchial tubes carbohydrate diaphragm gastric juices glucagon hydrocortisone icu immune system nicotine night guard polyuria radiologist rx scoliosis sebum stapes ultrasound umbilical cord.
//         </p>
//       </om-collapse-toggle>
//     </om-collapsed>
//   </om-collapse>
// </div>
//
// Styleguide 3.16
%fade-out {
  max-height: calc(#{$paragraph-line-height} * #{$fade-out-line-count});
  overflow: hidden;
}

[om-fade-out] {
  @extend %fade-out;
  position: relative;

  & > :last-child::after {
    @extend %fade-out;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, transparent, transparent 60%, rgba(255, 255, 255, .6) 80%, white);
    width: 100%;
    height: 100%;
    content: '';
  }
}
