$icon-opacity: 0.7;
$icon-full-opacity: 1;

.om-icon {
  line-height: $base-line-height;

  &.fa {
    opacity: $icon-opacity;
    background-color: unset;
    color: $icon-color;

    &:hover {
      opacity: $icon-full-opacity;
    }

    &.fa-trash-o {
      opacity: $icon-full-opacity;
    }
  }
}

.om-title-icon {
  opacity: $icon-opacity;
  cursor: pointer;
  color: $white;

  &:hover,
  &:focus {
    opacity: $icon-full-opacity;
  }
}

.fa-circle {
  font-size: 0.8em;

  &.warning {
    color: $blue1;
  }
}

.alert {
  color: $critical;
}

.om-form-spinner-icon {
  line-height: 2.5;
}

.fa-trash {
  opacity: $icon-opacity;
  color: $gray6;
}

.encounter-icon {
  color: $primary;
}

.results-icon {
  color: $red5;
}

.fa-envelope-o {
  color: $primary;
}

.fa-clipboard {
  color: $yellow5;
}

.fa-pencil-square-o {
  color: $yellow5;
}

.fa-inbox {
  color: $green4;
}

.default-icon {
  color: $green5;
}

.fa-flask {
  vertical-align: super;
  color: $blue4;
  font-size: $base-font-size * 1.7;
}

.fa-shopping-cart {
  color: $primary;
  font-size: 1.5em;
}
