$attachment-color: $body-font-color;
$attachment-background-color: $gray2;
$attachment-border-color: $gray2;
$messaging-docked-width: 504px;
$messaging-docked-height: 504px;

$docked-scrollable-height: calc(
  #{$messaging-docked-height} - #{$tabs-height} - #{$tabs-subheading-height} - #{$tabs-action-bar-with-scrollable-sibling-height}
);
%docked-scrollable-area-with-subheading-and-action-bar {
  height: $docked-scrollable-height;
  overflow: auto;
}

om-messaging {
  // remove max-height limitation on expanded messages to prevent overflow on long messages
  .om-expanded {
    max-height: none;
  }

  .om-autosave-button {
    min-width: rem-calc(140);
  }

  &[docked] {
    position: absolute;
    right: 0;
    bottom: $global-spacing;
    border: 1px solid $gray2;
    width: $messaging-docked-width;

    * > .om-tab-subheading + .scrollable-with-action-bar-sibling {
      background-color: $white;

      > [scrollable] {
        @extend %docked-scrollable-area-with-subheading-and-action-bar;
      }
    }

    &.minimized {
      height: $tabs-subheading-height;
    }
  }

  .attachment {
    @extend %flat-button-style;
    @include button-style($attachment-background-color, $attachment-border-color, $attachment-color);
    cursor: auto;
    &:hover,
    &:focus {
      background-color: $attachment-background-color;
    }
  }

  .errors {
    color: $warning-message-color;
  }

  om-search label .om-input.no-padding {
    padding: 0;
  }
}

.move_message_form {
  min-height: 220px;

  .new_patient_info {
    padding: 8px;
    height: 60px;

    om-profile-image-bubble {
      float: left;
      width: 45px;
      height: 45px;

      span {
        width: 45px;
        height: 45px;
      }
    }

    .new_patient_text {
      margin-left: $global-spacing;

      .new_patient_name {
        font-weight: bold;
      }
    }
  }

  .action-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
