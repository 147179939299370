@import 'extensions';
.om-fade {
  transition: opacity $animation-duration linear;
  opacity: 1;

  &.ng-hide {
    opacity: 0;
  }
}

om-collapse,
[om-collapse],
.om-collapse,
.om-collapse-expanded {
  @extend %easing-animation;

  & > .om-expanded,
  & > .om-collapsed {
    &.ng-hide-add {
      @extend %easing-animation;
    }

    &.ng-hide-remove {
      transition: all $animation-duration ease-out;
    }

    &.ng-hide {
      opacity: 0;
      max-height: 0;
    }
  }
}
