.om-icon {
  display: inline-block;
  background-position: center;
  @supports (mask-image: url()) {
    background-color: $default-icon-color;
    mask-position: center;
  }
  width: $default-icon-width;
  height: $default-icon-height;
  vertical-align: top;

  &.icon-flask,
  &.icon-follow-up,
  &.icon-questionnaire {
    width: 24px;
    height: 30px;
  }

  &[class*='-large'] {
    @extend %large-icon;
  }

  &.-yellow {
    background-color: $warning-message-color;
  }

  &.-red {
    background-color: $red5;
  }

  &.-green {
    background-color: $green5;
  }

  &.-gray {
    background-color: $gray4;
  }

  &.-blue {
    background-color: $primary;
  }
}

button.om-icon {
  width: $default-icon-width;
  min-width: $default-icon-width;
  height: $default-icon-height;
}

om-layout .om-icon {
  align-self: center;
}

// Add new
//
// Add a new item to a list.
//
// A large icon, only for use in "title" contexts, like the left-hand column.
//
// Markup:
// <div ng-module="onelifeUi.shared.card">
//   <om-card>
//     <om-card-title>
//       <om-title heading="Card Title">
//         <button class="clickable om-button -invisible -right-align">
//           <span class="om-icon -large icon-add-new"></span>
//         </button>
//       </om-title>
//     </om-card-title>
//   </om-card>
// </div>
//
// Styleguide 4.1

// Alert Info
//
// Indicates there is extra gender info for a patient
//
// Markup:
// <div om-layout om-layout-gutter>
//   <span om-layout om-layout-align="space-between center">
//     <span>Female<span> <span class="om-icon icon-alert-info"></span>
//   </span>
// </div>
//
// Styleguide 4.2

// Alert-warning
//
// Indicate a warning
//
// Markup:
// <div om-layout om-layout-gutter>
//   <span om-layout om-layout-align="space-between center" om-layout-gutter>
//     <span class="om-icon icon-alert-warning"></span><span>Something bad is going on</span>
//   </span>
// </div>
//
// Styleguide 4.3

// Alert-cancelled
//
// Indicate something is cancelled
//
// Markup:
// <div om-layout om-layout-gutter>
//   <span om-layout om-layout-align="space-between center" om-layout-gutter>
//     <span class="om-icon icon-alert-cancelled"></span><span>Something bad is going on</span>
//   </span>
// </div>
//
// Styleguide 4.4

// Alert-flag
//
// Flag something
//
// Markup:
// <div om-layout om-layout-gutter>
//   <span om-layout om-layout-align="space-between center" om-layout-gutter>
//     <span class="om-icon icon-alert-flag"></span><span>Something bad is going on</span>
//   </span>
// </div>
//
// Styleguide 4.5

// Attachment
//
// Indicate attachments to the main object.
//
// Markup:
// <div om-layout om-layout-gutter>
//   <span om-layout om-layout-align="space-between center">
//     <span class="om-icon icon-attachment"></span><span>Attachment name</span>
//   </span>
// </div>
//
// Styleguide 4.6

// Close
//
// Close the main object and return to where it came from.
//
// Only includes a `-white` version, the non-white version is a large icon and is not currently used.
//
// Markup:
// <div class="om-tab-subheading">
//   <span>Title</span>
//   <span class="clickable om-icon icon-close" om-stop-event="click"></span>
// </div>
//
// Styleguide 4.7

// Comments
//
// Expand/collapse comments on an object.
//
//
// Markup:
// <div ng-module="onelifeUi.shared.collapse">
//   <om-collapse>
//     <om-collapse-toggle>
//       <button class="om-button -link">3</button>
//       <om-collapsed class="om-icon icon-comment clickable"></om-collapsed>
//       <om-expanded class="om-icon icon-comment-filled clickable"></om-expanded>
//     </om-collapse-toggle>
//   </om-collapse>
//   <div class="om-tab-subheading">
//     <span>Title</span>
//     <om-collapse>
//       <om-collapse-toggle>
//         <om-collapsed class="om-icon icon-comment clickable"></om-collapsed>
//         <om-expanded class="om-icon icon-comment-filled clickable"></om-expanded>
//         <span>3</span>
//       </om-collapse-toggle>
//     </om-collapse>
//   </div>
// </div>
//
// Styleguide 4.8

// Maximize
//
// Maximize a "window" to continue working on its object.
//
// A large icon, only for use in "title" contexts.
//
// Markup:
// <div class="om-tab-subheading">
//   <span>Title</span>
//   <div om-layout om-layout-gutter>
//     <span class="clickable om-icon -large icon-maximize"></span>
//   </div>
// </div>
//
// Styleguide 4.9

// Minimize
//
// Minimize an object's "window" to unblock content from behind it.
//
// A large icon, only for use in "title" contexts.
//
// Markup:
// <div class="om-tab-subheading">
//   <span>Title</span>
//   <div om-layout om-layout-gutter>
//     <span class="clickable om-icon -large icon-minimize"></span>
//   </div>
// </div>
//
// Styleguide 4.10

// Push to note
//
// Include these details in the active note in the workspace.
//
// A large icon, only for use in "title" contexts, like the left-hand column.
//
// Markup:
// <div ng-module="onelifeUi.shared.card">
//   <om-card>
//     <om-card-title>
//       <om-title heading="Card Title">
//         <button class="clickable om-button -invisible -right-align">
//           <span class="om-icon -large icon-push-to-note"></span>
//         </button>
//       </om-title>
//     </om-card-title>
//   </om-card>
// </div>
//
// Styleguide 4.11

// Edit
//
// Click on the text near this field to edit it
//
// Markup:
// <div ng-module="onelifeUi.shared">
//   <div class="om-tab-subheading" om-layout-gutter>
//     <span om-flex om-layout om-layout-align="start center" om-layout-gutter-mini>
//        <span>Something editable</span>
//        <span om-flex="5" class="om-icon icon-edit"></span>
//     </span>
//   </div>
// </div>
//
// Styleguide 4.12

// UI Transfer
//
// Click on this to move something to another column
//
// Markup:
// <span class="om-icon icon-ui-transfer"></span>
//
// Styleguide 4.13

// Message
//
// You have a message
//
// Markup:
// <span class="om-icon icon-message"></span>
//
// Styleguide 4.14

// Templates
//
// Select and insert a template into the attached input.
//
// Markup:
// <span class="om-icon icon-templates"></span>
//
// Styleguide 4.15

// Attach
//
// Select and upload an attachment to the main object.
//
// Markup:
// <span class="om-icon icon-attach"></span>
//
// Styleguide 4.16

// Delete
//
// Delete the attached object.
//
// Markup:
// <div om-layout om-layout-align="start center" om-layout-gutter-mini>
//   <span>Object</span>
//   <span class="clickable om-icon icon-delete" ng-click="delete()"></span>
// </div>
//
// Styleguide 4.17

// Preferred
//
// The indicated option is a preferred choice.
//
// Markup:
// <div om-layout om-layout-align="start center" om-layout-gutter-mini>
//   <span class="om-icon icon-preferred"></span>
//   <span>Object</span>
// </div>
//
// Styleguide 4.18

// Rating negative
//
// The indicated option is rated negatively.
//
// Markup:
// <div om-layout om-layout-align="start center" om-layout-gutter-mini>
//   <span class="om-icon icon-rating-negative"></span>
//   <span>Object</span>
// </div>
//
// Styleguide 4.19
