// Detail list
//
// A list of items with details, kind of like a DL element.
//
// markup:
// <om-detail-list>
//   <om-detail-list-item>
//     <om-detail-list-item-title>
//       A. Person
//     </om-detail-list-item-title>
//     <om-detail-list-item-body>
//       45
//     </om-detail-list-item-body>
//   </om-detail-list-item>
// </om-detail-list>
//
// Styleguide 3.18


.om-detail-list .om-detail-list-item {
  .om-detail-list-item-title {
    cursor: pointer;
    padding: $om-item-padding;
  }

  &:not(:first-of-type) {
    .om-detail-list-item-title {
      @include top-border(1px, dotted);
      &:hover {
        background: $blue1;
      }
    }
  }
}
