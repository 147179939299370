om-consultant {
  display: block;

  &:hover {
    .consultant-lower-action {
      visibility: visible;
    }
  }

  .consultant-inactive {
    color: $gray3;
  }

  .consultant-icon {
    display: inline-block;
    border-radius: 50%;
    background-color: $green5;
    padding: 8px;
    width: 40px;
    height: 40px;
    &.network-unknown {
      background-color: $gray3;
    }
    &.network-in {
      background-color: $green5;
    }
    &.network-out {
      background-color: $yellow6;
    }
    &.network-inactive {
      background-color: $gray3;
    }
    .om-icon {
      background-color: $white;
    }
  }

  .consultant-info {
    .primary-name {
      font-weight: $font-weight-semibold;
    }
    .om-typeahead {
      margin-top: 9px;

      p.specialty-label {
        margin-bottom: 7px;
        color: $gray5;
      }
    }
  }

  .consultant-network {
    font-weight: $font-weight-semibold;
    &.network-unknown {
      color: $gray4;
    }
    &.network-in {
      color: $green5;
    }
    &.network-out {
      color: $yellow6;
    }
  }

  .consultant-lower-action {
    visibility: hidden;
    cursor: default;
    font-weight: $font-weight-semibold;

    .om-button {
      min-width: 0;
    }
  }

  .consultant-ratings {
    .problematic {
      color: $gray5;
    }
  }
}

.popover {
  &.consultant-popover {
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -16px;
      z-index: -1;
      content: '';
      pointer-events: all;
    }
    left: -400px;
    border-color: $gray2;
    box-shadow: 0 1.5px 5px $gray2;
    width: 300px;
    .problematic {
      color: $yellow6;
    }
    .arrow {
      border-right-color: $gray2;
    }
  }
}
