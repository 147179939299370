$border-color: $gray2;

.note-body {
  table {
    width: 100%;
  }

  a {
    font-size: inherit;
  }

  th,
  td {
    border: solid 1px $border-color;
    padding: 3px;
    word-wrap: break-word;
  }
}
