.om-tabs-nav {
  display: flex;
  box-shadow: 0 1px $tabs-shadow-color;
  background-color: $tabs-background-color;
  height: $tabs-height;

  li {
    @extend %h4;
    @extend %bold-type;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 $tabs-padding;
    color: $tabs-font-color;

    &.active {
      background-color: $tabs-active-background-color;
      color: $tabs-active-color;
    }
  }
}

.om-tab {
  overflow-x: auto;

  [scrollable],
  .scrollable {
    @extend %scrollable-area;
  }

  .locked {
    max-height: $page-header-offset-height;
    overflow: hidden;
  }

  &-content {
    background-color: $tabs-active-background-color;

    .empty-tab-message {
      @extend %bold-type;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      padding: $tabs-empty-padding 0;
      text-align: center;
      color: $gray4;
    }
  }

  &-subheading {
    @extend %base-heading-type;
    @extend %h3;
    @extend %om-header;
    @extend %display-flex;
    align-items: center;
    justify-content: space-between;
    text-shadow: $primary-heading-shadow;

    + [scrollable],
    + .scrollable {
      @extend %scrollable-area-with-subheading;
    }

    + .scrollable-with-action-bar-sibling > [scrollable],
    + * > .scrollable-with-action-bar-sibling > [scrollable],
    + .scrollable-with-action-bar-sibling > .scrollable {
      @extend %scrollable-area-with-subheading-and-action-bar;
    }

    .om-icon {
      background-color: $white;
    }
  }
}

// Allow more granular components by removing the DOM dependency between tabs, scrollable, and the action bar.
// Instead, use an explicit value to get the styles we want.
[scrollable*='subheading'][scrollable*='action-bar'] {
  @extend %scrollable-area-with-subheading-and-action-bar;
}
