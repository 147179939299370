$search-filters-font-color: $body-font-color !default;
$search-box-height: calc(#{$input-font-size} + 3 * #{$global-spacing});
$search-filter-height: calc(#{$list-line-height} + #{$global-spacing} / 2);

.om-search-box {
  @extend %padding-normal-horizontal;
  position: relative;
  margin: $global-spacing 0;

  > .om-input {
    padding: $global-spacing / 2 $global-spacing;
    padding-left: 2rem;
    font-family: $input-font-family, FontAwesome;
  }

  > .fa {
    position: absolute;
    top: 28%;
    right: 4 * $global-spacing;
    z-index: 1;
    cursor: pointer;
    color: $gray3;
    &.fa-search {
      left: 1.5rem;
      width: 14px;
      font-size: 14px;
    }
  }
}

.om-search-filters {
  @extend %display-flex;
  @extend %padding-half-horizontal;
  justify-content: space-between;
  border-bottom: solid 1px $gray2;
  padding-bottom: rem-calc(2);

  .om-search-filters-label {
    @extend %bold-type;
    line-height: inherit;
  }

  ul li {
    line-height: rem-calc(22);
  }

  a {
    padding-bottom: rem-calc(3);
    text-decoration: none;
    color: $gray5;

    &:hover {
      border-bottom: solid 2px $gray3;
    }

    &.active {
      border-bottom: solid 2px $search-filters-active-font-color;
      color: $search-filters-active-font-color;
    }
  }
}
