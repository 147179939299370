.button-group {
  display: inline-block;

  input {
    position: absolute;
    opacity: 0;
  }
  label {
    display: inline-block;
    border: solid 1px black;
    border-left: none;
    cursor: pointer;
    padding: 4px 16px;
    text-align: center;
  }
  div:first-child label {
    border-left: solid 1px black;
    border-radius: 2px 0 0 2px;
  }
  div:last-child label {
    border-radius: 0 2px 2px 0;
  }
  input:checked + label {
    background: $primary;
    color: white;
  }
}
