.om-nav {
  display: flex;
  align-items: center;
  background-color: $nav-background-color;
  height: $nav-height;

  &-item {
    @extend %base-nav-item;
    cursor: pointer;

    &:hover {
      background-color: $nav-hover-background-color;
    }

    a {
      text-decoration: none;
      color: $nav-font-color;
    }
  }

  &-menu li {
    @extend %dropdown-item;
    text-align: center;
  }

  .fa {
    margin: 0 $global-spacing;
  }
}

.om-logo {
  @extend %base-nav-item;
  img {
    width: 18px;
    height: 18px;
  }
}

.img-provider {
  display: block;
  position: relative;
  border-radius: $global-spacing;
  width: 30px;
  height: 30px;
  color: transparent;
  object-fit: cover;

  &:after {
    @extend %hidden-broken-placeholder-hack;
    right: 0;
    background-color: $nav-background-color;
  }
}
