$orders-dropdown-width: rem-calc(120);

om-orders-list,
omg-orders-list {
  .om-button.-primary {
    min-width: $orders-dropdown-width;
  }
  .dropdown-menu {
    min-width: $orders-dropdown-width;
    text-shadow: none;
  }

  // Must be tall enough with a small order list to display all "Create Order" options
  .create-order-menu-container {
    min-height: 16rem;
  }
}
