// Badges
//
// Easily highlight new or unread items by adding an <em>om-badge</em> to links, tabs, and more.
//
// .-warning	              - less alarming color for badges that need attention
// .-muted                  - muted color for badges
//
// Markup:
// <a href="#">Inbox <span class="om-badge {{modifier_class}}">53</span></a>
//
// Styleguide 3.1

.om-badge {
  @extend %om-badge-default;
  background-color: $badge-base-color;
  padding: $badge-padding;

  &.-muted {
    @extend %muted-badge;
  }

  &.-warning {
    @extend %warning-badge;
  }
}
