// Textarea With Info
//
// A textarea with a counter and description.
//   This textarea supports ngModel.
//
//   A character-warning option shows the maximum suggested number of characters in a character counter.
//   When text exceeds the counter, it changes color to indicate invalidation but does not enforce validation based on the text length.
//
//   A description option puts a description string in the lower right corner. It is up to the consumer to make sure
//     this text does not exceed the width of the textarea.
//
//   A placeholder option takes a placeholder to display.
//
//   A use-placeholder-if-empty option configures the component so that when the text is empty, focusing on the textarea
//     copies the placeholder text to the model. When this is true, the counter takes into account the placeholder length.
//
//   A function can be passed to the attribute on-placeholder-edit="" that will get invoked when use-placeholder-if-empty is true
//     and the placeholder text gets copied to the model.
//
// Markup:
// <div ng-modules="templates, monospaced.elastic, onelifeUi.shared">
//   <span ng-init="somethingGreat = ''">
//   <span ng-init="placeholder = 'Enter something great here.'">
//   <om-text-area-with-info
//     name="somethingGreat"
//     placeholder="placeholder"
//     ng-model="somethingGreat"
//     use-placeholder-if-empty="true"
//     character-warning="250"
//     description="Visible to patient">
//   </om-text-area-with-info>
//   <p>somethingGreat:</p>
//   <pre>\{{ somethingGreat }}</pre>
// </div>
//
// Styleguide 3.13

.om-form.ng-submitted om-text-area-with-info.ng-invalid:not(.ui-select-container) {
  border: 0;
  textarea.text-area {
    border: solid $input-border-width $input-border-color-invalid;
  }
}

om-text-area-with-info {
  display: block;
  position: relative;

  textarea.text-area {
    min-height: 100px;
  }

  .counter {
    position: absolute;
    bottom: $global-spacing;
    left: $global-spacing;
    color: $brown5;

    &.-warning {
      color: $critical;
    }
  }

  .description {
    position: absolute;
    right: $global-spacing;
    bottom: $global-spacing;
    color: $brown5;
  }
}
