$ui-select-min-height: $base-line-height;
$ui-select-min-width: $button-min-width;
$ui-select-choice-dropdown-distance: -(($global-spacing * 2) + rem-calc($input-border-radius));

.ui-select-dropdown-arrow {
  .ui-select-dropdown-arrow-sibling {
    padding-right: rem-calc(28px);
  }

  &::after {
    position: absolute;
    top: 5px;
    right: 10px;
    text-align: right;
    text-decoration: none;
    color: $input-font-color;
    font-family: 'FontAwesome';
    content: '\f0d7'; // fa css content codes http://astronautweb.co/snippet/font-awesome/
    pointer-events: none;
  }
}

// Temporary fix for https://github.com/angular-ui/ui-select/issues/1731
.ui-select-choices.ui-select-choices-content.ui-select-dropdown.dropdown-menu {
  opacity: 1 !important; // sass-lint:disable-line no-important
}

.ui-select-container {
  position: relative;
  vertical-align: top;

  &:active,
  &:focus {
    outline: none;
  }

  // adds a down arrow to indicate it's more like a pull down then an auto-suggest
  &.-limited-choice {
    @extend .ui-select-dropdown-arrow;
  }

  &.-inline {
    display: inline-block;

    .ui-select-match {
      margin-right: 18px;
    }
  }

  &.-uniform-height {
    .ui-select-bootstrap .ui-select-choices-row-inner,
    .ui-select-choices-row-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: $ui-select-min-width;
      min-height: $ui-select-min-height;
    }
  }

  &.-search-disabled {
    span {
      cursor: default;
    }

    input {
      cursor: default;
      color: transparent;
    }

    [placeholder]:focus::placeholder {
      outline: none;
      color: $body-font-color;
    }

    &.open > .ui-select-choices {
      margin-top: $ui-select-choice-dropdown-distance;
    }
  }

  &[disabled] {
    &::after {
      color: $gray3;
    }
    .ui-select-match {
      border-color: $gray3;
      background-color: $gray1;
    }
  }
}

.ui-select-bootstrap > .ui-select-choices,
.ui-select-bootstrap > .ui-select-no-choice,
.ui-select-choices,
.ui-select-no-choice {
  max-height: 250px;
  overflow: auto;

  .om-icon.clickable {
    opacity: 1;
    color: $primary;

    &:hover {
      color: $blue4;
    }
  }

  .active .om-icon.clickable {
    color: $body-font-color;

    &:hover {
      color: $blue6;
    }
  }
}

.open {
  & > .ui-select-choices {
    @extend %dropdown-body;
    z-index: $zindex-ui-select;
    width: auto;
    min-width: 100%;
  }

  &.direction-up > .ui-select-choices {
    margin-top: -$dropdown-distance-from-trigger;
  }
}

.ui-select-placeholder {
  max-height: calc(#{$base-line-height} + (#{$global-spacing} * 2));

  &.text-muted {
    color: $input-border-color-disabled;
    font-style: italic;
  }
}

.ui-select-offscreen {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  outline: none;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.ui-select-search.form-control {
  @include input-style;

  margin: 0;
  padding: $input-padding;
  width: 100%;
  min-width: $ui-select-min-width;
}

.ui-select-bootstrap .ui-select-choices-row,
.ui-select-choices-row {
  .ui-select-bootstrap .ui-select-choices-row-inner,
  span.ui-select-choices-row-inner {
    @extend %dropdown-item;
  }

  &.active {
    .ui-select-choices-row-inner {
      @extend %base-typography;
      background-color: $blue1;
      color: $body-font-color;
    }

    .caption {
      @extend %caption-text;
    }
  }
}

.ui-select-highlight {
  @extend %bold-type;
}

.ui-select-match {
  @include input-style;

  padding: $input-padding;
  width: 100%;
  text-align: left;

  &.btn-default-focus {
    @extend %focused-input-style;
  }

  .ui-select-toggle {
    display: block;
    width: 100%;
    min-height: $ui-select-min-height;
  }

  .ui-select-bootstrap .ui-select-match-text,
  .ui-select-match-text {
    display: inline;
    float: none;
    padding: 0;
    width: auto;

    span {
      display: inline;
      width: auto;
    }
  }

  &.-flush-bottom {
    @extend %flush-bottom;
  }
}

.invalid label {
  color: $critical;
}

.invalid .btn-default-focus,
.invalid button {
  margin-bottom: 0;
  border-color: $critical;
}

.invalid .alert {
  margin-bottom: $input-padding;
}

.invalid .open > .ui-select-choices {
  margin-top: 0;
}
