// Label
//
// Like an <em>om-badge</em> but for calling attention to short phrases/lines of text.
// The default label color is a call to action.
//
// .-inactive            - for a label that requires no action
// .-warning             - for a label that needs attention
// .-success             - for a label that indicates success
//
// Markup:
// <span class="om-label {{modifier_class}}">Some Label</span>
//
// Styleguide 3.1.2

.om-label {
  @extend %om-badge-default;
  background-color: $label-default-background-color;
  padding: $global-spacing;
  min-width: $label-min-width;
  text-transform: capitalize;

  &.-inactive {
    @extend %muted-badge;
  }

  &.-warning {
    @extend %warning-badge;
  }

  &.-success {
    @extend %success-badge;
  }
}
