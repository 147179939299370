@mixin fade-under($color) {
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
    left: -100%;
    background-image: linear-gradient(to right, transparent, rgba($color, .6) 20%, $color 50%);
    width: 200%;
    height: 100%;
    content: '';

    pointer-events: none;
  }
}

om-file-link {
  & > .om-button {
    @extend %containing-block;
  }

  .icon-delete {
    background-color: $button-secondary-color;
  }

  .om-file-link-delete {
    $hoverColor: darken($button-secondary-background-color, 5%); // copied from button styles
    @include fade-under($hoverColor);
    position: absolute;
    right: 0;
    background-color: $button-secondary-background-color;
  }
}
