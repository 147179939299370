// Lists and Items
//
// Items are generally expected to be in lists.  They have nice properties like borders and standard padding.
//
// .-grouped	              - adds a rounded frame around the list
//
// markup:
// <div ng-module="onelifeUi.shared">
//  <div class="om-list {{modifier_class}}">
//    <div class="om-item">Item 1</div>
//    <div class="om-item">Item 2</div>
//    <div class="om-item">Item 3</div>
//  </div>
// </div>
//
// Styleguide 3.4

@keyframes openSpace {
  to {
    max-height: $item-open-max-height;
  }
}
@keyframes moveIn {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.om-item {
  display: block;
  padding: $om-item-padding;

  &.ng-enter {
    transform: translateX(-100%);
    animation: openSpace 0.1s ease forwards, moveIn 0.2s 0.1s ease forwards;
    opacity: 0;
    max-height: 0;
  }

  &.-warning {
    background-color: $item-warn-background-color;
  }

  .om-item {
    margin-bottom: 0;
    background: none;
    padding: 0;
  }

  &.-contrasted-dark {
    background-color: $item-dark-background-color;
  }

  &.-contrasted-darker {
    background-color: $item-darker-background-color;
  }

  &.-inset {
    box-shadow: 3px 0 $gray3 inset;
  }

  &.-menu-option {
    @extend %selectable-item;
    @extend %base-heading-type;
    @extend %h3;
    background-color: $white;
    color: $item-option-anchor-color;

    &:disabled {
      cursor: not-allowed;
    }

    .errors {
      text-align: right;
      color: $body-font-color;
    }
  }

  &.-selectable {
    @extend %selectable-item;
  }

  &.-bordered {
    border-bottom: 1px solid $item-border-color;

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.om-list {
  display: block;
  position: relative;

  &.-compact {
    > .om-item {
      padding: $global-spacing / 4;
    }
  }

  &.-processing {
    opacity: 0.5;
    > .om-item {
      cursor: not-allowed;
    }
  }

  &.-grouped {
    @extend %rounded-frame;

    > .om-item {
      background-color: transparent;
    }
  }

  &.-borderless > .om-item {
    &:not(:first-of-type) {
      border-top: 0;
    }
  }

  > om-collapse,
  > .om-item[om-collapse] {
    @extend %selectable-item;
    cursor: default;

    &.om-collapse-expanded,
    &.om-collapse-expanded:hover {
      @extend %expanded-content;
    }
  }

  > .om-item {
    padding: $om-item-padding;
    &:not(:first-of-type) {
      @include top-border(1px, solid);
    }

    p {
      margin-bottom: 0;

      &.-with-margin {
        margin-bottom: $global-spacing;
      }
    }

    .om-list {
      margin-top: (2 * $global-spacing) - $paragraph-margin-bottom;
      margin-bottom: (2 * $global-spacing) - $paragraph-margin-bottom;
      background-color: $inner-list-background-color;

      .om-item {
        border: 0;
        padding-top: $global-spacing / 2;
        padding-bottom: $global-spacing / 2;

        > .om-collapsed {
          padding: $global-spacing / 2;
        }
      }

      .om-collapse-expanded {
        background-color: $blue1;
      }
    }
  }
}
