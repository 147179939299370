// @font-face {
//   font-family: 'Open Sans';
//   font-weight: normal;
//   font-style: normal;
//   src: url('fonts/OpenSans-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Open Sans Semi-Bold';
//   font-weight: normal;
//   font-style: normal;
//   src: url('fonts/OpenSans-SemiBold.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Open Sans Italic';
//   font-weight: normal;
//   font-style: normal;
//   src: url('fonts/OpenSans-Italic.ttf') format('truetype');
// }

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/Inter-Regular.woff2?v=3.7') format('woff2'), url('fonts/Inter-Regular.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: italic;
  src: url('fonts/Inter-Italic.woff2?v=3.7') format('woff2'), url('fonts/Inter-Italic.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url('fonts/Inter-Medium.woff2?v=3.7') format('woff2'), url('fonts/Inter-Medium.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: italic;
  src: url('fonts/Inter-MediumItalic.woff2?v=3.7') format('woff2'), url('fonts/Inter-MediumItalic.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: url('fonts/Inter-SemiBold.woff2?v=3.7') format('woff2'), url('fonts/Inter-SemiBold.woff?v=3.7') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: italic;
  src: url('fonts/Inter-SemiBoldItalic.woff2?v=3.7') format('woff2'), url('fonts/Inter-SemiBoldItalic.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url('fonts/Inter-Bold.woff2?v=3.7') format('woff2'), url('fonts/Inter-Bold.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: italic;
  src: url('fonts/Inter-BoldItalic.woff2?v=3.7') format('woff2'), url('fonts/Inter-BoldItalic.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: normal;
  src: url('fonts/Inter-ExtraBold.woff2?v=3.7') format('woff2'), url('fonts/Inter-ExtraBold.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: italic;
  src: url('fonts/Inter-ExtraBoldItalic.woff2?v=3.7') format('woff2'), url('fonts/Inter-ExtraBoldItalic.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  src: url('fonts/Inter-Black.woff2?v=3.7') format('woff2'), url('fonts/Inter-Black.woff?v=3.7') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: italic;
  src: url('fonts/Inter-BlackItalic.woff2?v=3.7') format('woff2'), url('fonts/Inter-BlackItalic.woff?v=3.7') format('woff');
}
