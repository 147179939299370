om-supporting-documents {
  .om-supporting-empty-image {
    width: 90%;
  }
  .om-supporting-empty {
    padding: 20px 80px;
    text-align: center;
  }
  .om-supporting-empty-header {
    @extend %bold-type;
    font-size: rem-calc(16);
  }
  .om-order-document-item {
    margin: 0.3rem 0;
  }

  .unsuccessful-documents-explainer {
    color: $yellow6;
  }
}
