// PDF Viewer
//
// Shows pdfs in an iframe
$padding-normal-total-height: calc( 2 * #{$normal-padding} );
$base-line-with-padding-height: calc( #{$base-line-height} + #{$padding-normal-total-height} );
$note-heading-height: calc( #{$h3-line-height} + 2 * #{$section-title-padding} );
$task-assignment-height: $base-line-with-padding-height;
$action-bar-height: $base-line-with-padding-height;
$file-link-height: $base-line-with-padding-height;

$iframe-height-scrollable-with-subheading: calc(
  #{$tabs-scrollable-with-subheading-height} - #{$note-heading-height} -
  #{$file-link-height} - #{$padding-normal-total-height}
);

$iframe-height-scrollable-with-subheading-and-action-bar: calc(
  #{$tabs-scrollable-with-subheading-and-action-bar-height} -
  #{$action-bar-height} - #{$file-link-height} - #{$task-assignment-height} -
  #{$padding-normal-total-height}
);

om-pdf-viewer {
  &.timeline-column iframe {
    width: 100%;
    height: $iframe-height-scrollable-with-subheading;
  }

  &.workspace-column iframe {
    width: 100%;
    height: $iframe-height-scrollable-with-subheading-and-action-bar;
  }
}
