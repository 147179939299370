// Split Button Dropdown
//
// A button with a secondary list of options.
// Use `om-stop-event="click"` on buttons or option items (`<li>`) to prevent the click event from bubbling up to parent components (such as `om-collapse`).
//
// Primary Buttons:
//
// Markup:
// <div>
//   <h3>Primary:</h3>
//   <div om-layout om-layout-gutter om-layout-align="start center" ng-init="optionClicked = 'nothing clicked'">
//     <div class="om-dropdown" uib-dropdown>
//       <div class="button-group">
//         <button type="button"
//                 class="om-button -primary"
//                 id="checkOutDropdown"
//                 om-collapse-toggle
//                 ng-click="optionClicked = 'Check out clicked'">Check Out</button>
//         <button type="button" class="-primary" uib-dropdown-toggle>
//           <i class="fa fa-caret-down"></i>
//         </button>
//       </div>
//       <ul uib-dropdown-menu
//           role="menu"
//           class="dropdown-menu"
//           aria-labelledby="checkOutDropdown">
//           <li om-collapse-toggle role="menuitem" type="button" ng-click="optionClicked = 'Option 1 clicked';">Option 1</li>
//           <li om-collapse-toggle role="menuitem" type="button" ng-click="optionClicked = 'Option 2 clicked';">Option 2</li>
//           <li om-collapse-toggle role="menuitem" type="button" ng-click="optionClicked = 'Option 3 clicked';">Option 3</li>
//       </ul>
//     </div>
//     <strong>optionClicked:</strong>
//     <pre>{{ optionClicked }}</pre>
//   </div>
//   <br>
//   <h3>Secondary:</h3>
//   <div om-layout om-layout-gutter om-layout-align="start center" ng-init="optionClicked = 'nothing clicked'">
//     <div class="om-dropdown" uib-dropdown>
//       <div class="button-group">
//         <button type="button"
//                 class="om-button -secondary"
//                 id="checkOutDropdown"
//                 om-collapse-toggle
//                 ng-click="optionClicked = 'Check out clicked'">Check Out</button>
//         <button type="button" class="-secondary" uib-dropdown-toggle>
//           <i class="fa fa-caret-down"></i>
//         </button>
//       </div>
//       <ul uib-dropdown-menu
//           role="menu"
//           class="dropdown-menu"
//           aria-labelledby="checkOutDropdown">
//           <li om-collapse-toggle role="menuitem" type="button" ng-click="optionClicked = 'Option 1 clicked';">Option 1</li>
//           <li om-collapse-toggle role="menuitem" type="button" ng-click="optionClicked = 'Option 2 clicked';">Option 2</li>
//           <li om-collapse-toggle role="menuitem" type="button" ng-click="optionClicked = 'Option 3 clicked';">Option 3</li>
//       </ul>
//     </div>
//     <strong>optionClicked:</strong>
//     <pre>{{ optionClicked }}</pre>
//   </div>
// </div>
//
// Styleguide 3.12.3

.om-dropdown,
.om-dropup {
  @extend %display-flex;
  position: relative;

  .dropdown-menu {
    @extend %dropdown-body;
    display: none;
  }

  .button-group {
    @extend %display-flex;

    button {
      @extend %dropdown-toggle-button;
      &.-primary {
        @include button-style($button-primary-background-color, transparent, $button-primary-color);
        // Prevent the 'stuck' focus bg color on the dropdown toggle when closing dropdowns
        &:focus {
          background-color: $button-primary-background-color;
        }
      }
      &.-secondary {
        @include button-style($button-secondary-background-color, transparent, $button-secondary-color);
        &:focus {
          background-color: $button-secondary-background-color;
        }
      }
      &:first-child:not(:only-child) {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.open {
    > .dropdown-menu {
      display: block;

      > li, [role='menuitem'] {
        @extend %dropdown-item;
        min-width: $dropdown-menu-item-min-width;
      }
    }
  }

  &.-slim {
    .dropdown-menu {
      @extend %dropdown-body;
      box-shadow: none;
      min-width: $dropdown-min-width;

      li {
        padding: 0;
      }

      button {
        @include button-style($dropdown-slim-background-color, $dropdown-slim-border-color, $body-font-color);
        border: none;
        cursor: default;
        min-width: $dropdown-min-width;
      }
    }

    .button-group {
      button {
        @include button-style($dropdown-slim-background-color, $green6, $body-font-color);
        cursor: default;
      }
    }
  }
}

.om-dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: $dropdown-distance-from-trigger;
  }
}
