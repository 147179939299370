// Tooltips
//
// Tooltips display a short line of additional information on top of a page.
// We're using a third party library to handle interaction. Some basic examples are below.
// <strong>For additional positioning, speed, and size configurations see <a href="https://github.com/720kb/angular-tooltips#doc" target="_blank">720kb.tooltips</a>.</strong>
//
// Markup:
// <div ng-module="onelifeUi">
//    <a href="#" tooltips tooltip-template="I'm always to the right." tooltip-side="right">Tooltip me</a><br />
//    <a href="#" tooltips tooltip-template="This tooltip has smaller text" tooltip-side="smart" tooltip-size="small">Small tooltip</a><br />
//    <span tooltips
//          tooltip-side="right"
//          tooltip-size="wide"
//          tooltip-template="Are you sure?"><button class="om-button -primary">Log out</button></span>
// </div>
//
// Styleguide 3.9
$tooltip-color: $gray6;
$tooltip-arrow: calc(#{$base-font-size} / 2) solid $tooltip-color;
$tooltip-arrow-cutout: calc(#{$base-font-size} / 2) solid transparent;
$wide-tooltip-width: 300px;
$max-tooltip-width: 400px;
$min-tooltip-width: 150px;

tooltip {
  display: inline;
  font-size: inherit;

  tip {
    z-index: $zindex-tooltips;
    background: $tooltip-color;
    padding: $global-spacing ($global-spacing * 2);
    min-width: $min-tooltip-width;
    max-width: $max-tooltip-width;
    text-align: center;
    text-transform: none;

    &-arrow {
      border-left: $tooltip-arrow;
    }
  }
}

.tooltips {
  tip tip-arrow {
    border-left-width: 0;
  }
}

[tooltip-size='wide'].tooltips {
  tip {
    padding: $global-spacing;
    width: $wide-tooltip-width;
    text-align: left;
  }
}

[tooltip-side='left'].tooltips {
  tip tip-arrow {
    border-left: $tooltip-arrow;
  }
}

[tooltip-side='right'].tooltips {
  tip tip-arrow {
    border-right: $tooltip-arrow;
  }
}

[tooltip-side='top'].tooltips,
[tooltip-side='top-right'].tooltips {
  tip tip-arrow {
    border-top: $tooltip-arrow;
  }
}

[tooltip-side='top-right'].tooltips {
  tip {
    top: -($global-spacing * 2);
    left: 100%;
    transform: translateX(-100%) translateY(-100%);

    tip-arrow {
      position: absolute;
      top: 100%;
      left: 100%;
      margin-left: calc(#{$base-font-size} * -2);
      border-right: $tooltip-arrow-cutout;
      border-left: $tooltip-arrow-cutout;
    }
  }
}

[tooltip-side='bottom'].tooltips {
  tip tip-arrow {
    border-bottom: $tooltip-arrow;
  }
}
