om-consultant-search {
  & > .om-list {
    overflow: auto;

    .om-item {
      &.consultant-inactive-container {
        &:hover {
          background-color: $gray1;
        }
      }

      &:hover {
        background-color: $blue1;
      }

      &:not(:first-of-type) {
        border-top: 0;

        &:before {
          @include top-border(1px, solid);
          display: block;
          margin: 0 auto;
          margin-top: -$global-spacing;
          padding-bottom: $global-spacing;
          width: 100%;
          content: '';
        }
      }
    }
  }

  & > .consultant-no-results {
    .consultant-no-results-body {
      margin: 40px 0;
      text-align: center;
      color: $gray5;

      .consultant-large-icon {
        margin-bottom: 16px;
        background-color: $gray3;
        width: 40px;
        height: 40px;
        mask-size: 40px;
      }
    }
  }
}
