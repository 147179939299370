$container-background-color: $white;
$toggle-inactive-color: $gray2;
$toggle-active-color: $green5;

.om-toggle-switch {
  margin-bottom: $global-spacing;

  input {
    position: absolute;
    visibility: hidden;
    margin-left: -9999px;
  }

  .toggle {
    display: block;
    position: relative;
    transition: background .4s;
    outline: none;
    border-radius: 25px;
    background-color: $container-background-color;
    cursor: pointer;
    padding: 2px;
    width: 45px;
    height: 25px;
    user-select: none;
  }

  .toggle::before,
  .toggle::after {
    display: block;
    position: absolute;
    content: '';
  }

  .toggle::before {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    transition: background .4s;
    border-radius: 18px;
    background-color: $toggle-inactive-color;
  }

  .toggle::after {
    top: 4px;
    bottom: 4px;
    left: 4px;
    transition: margin .4s, background .4s;
    border-radius: 18px;
    background-color: $container-background-color;
    width: 18px;
  }

  input:checked + .toggle::before {
    background-color: $toggle-active-color;
  }

  input:checked + .toggle::after {
    margin-left: 20px;
    background-color: $container-background-color;
  }
}
