// Profile Image Bubble
//
// A round "bubble" that either shows the user's profile image or their initials
//
// markup:
// <div ng-modules="templates, onelifeUi.shared">
//   <om-profile-image-bubble name="Sophie Krisch"></om-profile-image-bubble>
// </div>
//
// Styleguide 3.15
om-profile-image-bubble {
  > span {
    @extend %profile-image-bubble;
  }

  span {
    text-align: center;
    color: $white;

    &.combo-1 {
      background-color: $green2;
    }
    &.combo-2 {
      background-color: $blue2;
    }
    &.combo-3 {
      background-color: $yellow2;
    }
    &.combo-4 {
      background-color: $brown2;
    }
    &.combo-5 {
      background-color: $red2;
    }
  }
}
