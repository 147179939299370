// Modal
//
// A modal is a dialog box/popup window that is displayed on top of the current page. The `header` attribute is optional.
//
// markup:
// <div ng-modules="templates, onelifeUi.shared">
//   <om-modal template="'templates/styleguide/modal.html'" header="Hello, I'm a modal :)">
//     <button class="om-button -primary" ng-click="$modal.open()">Click me!</button>
//   </om-modal>
// </div>
//
// Styleguide 3.3

.om-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: $zindex-modal;
  background-color: $modal-backdrop-color;
  width: 100vw;
  height: 100vh;

  > .container {
    position: fixed;
    top: 10%;
    background-color: $modal-background-color;
    width: $modal-width;

    > .body {
      position: absolute;
      background-color: $modal-background-color;
      width: 100%;
      max-height: $modal-max-height;
      overflow-x: hidden;
      overflow-y: auto;

      [scrollable] {
        max-height: calc(#{$modal-max-height} / 2);
        overflow: auto;
      }
    }

    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $hr-color;
      padding: $global-spacing $modal-padding;

      > * {
        margin: 0;
      }
    }
  }
}
