$popover-background: $card-background-color;
$popover-max-width: 430px !default;
$popover-border-color: $gray4;
$popover-arrow-width: $base-font-size !default;
$popover-arrow-color: $popover-background !default;
$popover-arrow-outer-width: $popover-arrow-width + 1 !default;
$popover-arrow-outer-color: lighten($popover-border-color, 0.05) !default;

@mixin popover-arrow($side: top) {
  $direction: bottom;
  $cross-direction: left;

  @if $side == left {
    $direction: right;
    $cross-direction: top;
    margin-#{$cross-direction}: -$popover-arrow-outer-width;
  } @else if $side == right {
    $direction: left;
    $cross-direction: top;
    margin-#{$cross-direction}: -$popover-arrow-outer-width;
  } @else if $side == bottom {
    $direction: top;
    $cross-direction: left;
    margin-#{$cross-direction}: -$popover-arrow-outer-width;
  }

  #{$direction}: -$popover-arrow-outer-width;
  #{$cross-direction}: 50%;
  border-#{$direction}-width: 0;
  border-#{$side}-color: $popover-arrow-outer-color;

  &::after {
    #{$direction}: 1px;
    margin-#{$cross-direction}: -$popover-arrow-width;
    border-#{$direction}-width: 0;
    border-#{$side}-color: $popover-arrow-color;
    content: ' ';
  }
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  border: 1px solid $popover-border-color;
  box-shadow: shadow-style(normal, black-shadow);
  background-clip: padding-box;
  background-color: $popover-background;
  max-width: $popover-max-width;
  font-size: $base-font-size;
  &.top {
    margin-top: -$popover-arrow-width;

    > .arrow {
      @include popover-arrow(top);
    }
  }

  &.right {
    margin-left: $popover-arrow-width;

    > .arrow {
      @include popover-arrow(right);
    }
  }

  &.bottom {
    margin-top: $popover-arrow-width;

    > .arrow {
      @include popover-arrow(bottom);
    }
  }

  &.left {
    margin-left: -$popover-arrow-width;

    > .arrow {
      @include popover-arrow(left);
    }
  }

  > .arrow {
    border-width: $popover-arrow-outer-width;

    &,
    &::after {
      display: block;
      position: absolute;
      border-style: solid;
      border-color: transparent;
      width: 0;
      height: 0;
    }
    &::after {
      border-width: $popover-arrow-width;
      content: '';
    }
  }
  .popover-content > .om-list {
    margin: 0; // reset nested list margin top + bottom
  }
  &-title {
    @extend %h4;
    @extend %h4-section;
  }
}
