// Multi-select
//
// An input field that allows multiple values to be selected from a dropdown.
// selectable-values have 2 required properties: id - a unique identifier; multiSelectName - a string to display as its value
//   They can have as many other optional properties as desired.
//
//   A function can be passed to the attributes on-add="" and on-remove="" that will get invoked when a value is added or removed
//     to the ng-model, respectively.
//
// Markup:
// <div ng-modules="templates, ui.bootstrap.typeahead, onelifeUi.shared">
//   <span ng-init="selected = []">
//   <om-multi-select
//     ng-model="selected"
//     selectable-values="[
//      { id: 1, multiSelectName: 'apple'},
//      { id: 2, multiSelectName: 'banana'},
//      { id: 3, multiSelectName: 'orange'},
//      { id: 4, multiSelectName: 'pomegranate'}
//     ]">
//   </om-multi-select>
//     <p>Selected:</p>
//     <pre>\{{ selected | json }}</pre>
// </div>
//
// Styleguide 3.11

.om-form.ng-submitted om-multi-select.ng-invalid:not(.ui-select-container) {
  border: 0;
  .multi-select-container {
    border: solid $input-border-width $input-border-color-invalid;
  }
}

om-multi-select {
  display: block;

  .multi-select-container {
    @include input-style;
    cursor: text;
    padding: $input-padding;

    .om-label {
      vertical-align: initial;
    }

    .multi-select-typeahead {
      display: inline-block;
    }

    .multi-select-input {
      @include input-style;
      border: 0 !important; // sass-lint:disable-line no-important
    }

    .om-typeahead .dropdown-menu {
      width: auto;
    }
  }
}
