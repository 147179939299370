// Forms
//
// Includes functionality for autosaving.
// Updates submit button while in progress, and error messages when complete.
//
// markup:
// <om-form name="form" om-autosave="update()">
//   <p><input class="om-input" ng-model="value"></p>
//   <om-autosave-saving-message></om-autosave-saving-message>
//   <p>
//     <button type="submit"
//             class="om-button -primary"
//             ng-class="{ '-processing': form.saving }"
//             ng-disabled="form.saving"
//             om-autosave-complete-button="Update">Update</button>
//   </p>
// </om-form>
//
// Styleguide 3.17

label,
.label {
  @extend %label;

  &.-stacked {
    display: flex;
    flex-direction: column;
  }
}

.om-form {
  .om-autosave-saving-message {
    min-height: calc(#{$base-font-size} * 1.5 + #{$global-spacing});
  }
}

.radio-group {
  label {
    text-transform: none;
    color: $body-font-color;
  }
}

[ng-messages],
.om-messages {
  @extend %bold-type;
  color: $error-message-color;

  ul {
    list-style: disc inside none;
  }

  &.-success {
    color: $success-message-color;
  }

  &.-warning {
    color: $warning-message-color;
  }

  &.-progress {
    color: $progress-message-color;
  }
}

.om-form,
form {
  .ng-submitted,
  &.-immediate-errors,
  & .-immediate-errors {
    .ui-select-container.ng-invalid:not(.ng-dirty) {
      .ui-select-search,
      .ui-select-match {
        @extend %invalid-input-style;
      }
    }

    .ng-invalid:not(.ui-select-container) {
      @extend %invalid-input-style;
    }
  }
}

.om-form.-subform,
.-subform > .om-form {
  margin: 0 0 $global-spacing;
  background-color: $subform-background-color;
}
// This fixes a bug here: https://github.com/angular-ui/ui-select/issues/1575
// They claim to have a fix in, but it's still breaking for us.
.ui-select-search {
  width: 100% !important; // sass-lint:disable-line no-important
}

select {
  margin-bottom: $global-spacing;
  font-size: $select-font-size;
}
