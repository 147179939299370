@import '~@angular/cdk/overlay-prebuilt.css';
@import '~scss/variables';

.om-dialog-container {
  background-color: $modal-background-color;
}

.om-dialog-content {
  background-color: $modal-background-color;
  width: 100%;
  max-height: $modal-max-height;
  overflow: auto;

  [scrollable] {
    max-height: calc(#{$modal-max-height} / 2);
    overflow: auto;
  }
}

.om-dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $hr-color;
  background-color: $modal-background-color;
  padding: $global-spacing $modal-padding;

  > * {
    margin: 0;
  }
}

.om-dialog-actions {
  @extend .action-bar;
  @extend [om-layout];
  @extend [om-layout-fill];
  @extend [om-layout-gutter];
}
