.om-input,
textarea {
  @include input-style;

  @at-root textarea#{&} {
    @extend %base-typography;
    padding: $input-padding;
    min-height: 0;
    resize: none;

    &:focus {
      transition: height 50ms ease-in-out;
    }
  }

  &.mask-input {
    font-family: 'text-security-disc'; //fall back for browsers that don't support webkit-security - forces font to just be discs
    // sass-lint:disable no-vendor-prefixes
    -webkit-text-security: disc;
    // sass-lint:enable no-vendor-prefixes
  }

  &.-flush-bottom {
    @extend %flush-bottom;
  }

  &.-readonly,
  &[disabled] {
    border-color: $input-border-color-disabled;
    background-color: $input-secondary-background-color;
    cursor: not-allowed;
    color: $input-border-color-disabled;
  }

  &.-edit-in-place {
    border: none;
    background-color: inherit;
    color: inherit;
    font-size: inherit;
  }
}

.om-styled-select {
  position: relative;

  select {
    padding: $input-padding;
    padding-right: 18px;
    appearance: none;
  }

  &::before {
    position: absolute;
    top: 5px;
    right: 10px;
    text-align: right;
    text-decoration: none;
    color: $body-font-color;
    font-family: FontAwesome;
    content: '\f0d7';
    pointer-events: none;
  }
}
