.printable {
  display: none;
}

@media print {
  .printable {
    display: inherit;
  }

  // The following @page and body rules are a hack to prevent chrome from displaying the
  // page title and URL when printing. For details: https://stackoverflow.com/a/2780518/2441483
  @page {
    margin: 0;
    height: auto;
  }

  body {
    margin: 16mm;
    height: auto;
  }
}
