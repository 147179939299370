om-google-places {
  position: relative;

  .selected-location {
    position: absolute;
    top: 0;
    pointer-events: none;
  }

  .border {
    border-top: 1px solid $gray2;
  }
}
