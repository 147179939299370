om-file-upload-button {
  & > button {
    position: relative;
    overflow: hidden;

    & > input {
      position: absolute;
      top: 0;
      opacity: 0;
      margin-left: -10rem;
      height: 100%;
    }
  }
}
