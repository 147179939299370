.check-label {
  border: solid 1px $gray2;
  border-radius: $global-spacing / 2;
  padding: $global-spacing / 2;

  &.-unchecked {
    background-color: $blue1;
  }

  &.-checked {
    background-color: $green3;
  }
}
