/*
 * Styles for Calendar control component
 * Needed to be placed here to be global
 */
.ui-datepicker {
  border: 1px solid $gray2;
  background-color: $white;
  padding: $input-padding;
  min-width: 20em;
  color: $gray6;

  &:not(.ui-datepicker-inline) {
    border: 1px solid $gray1;
    box-shadow: shadow-style(focused, black-shadow);
  }
  &:not(.ui-state-disabled) {
    .ui-datepicker-header .ui-datepicker-prev:hover,
    .ui-datepicker-header .ui-datepicker-next:hover {
      color: $primary;
    }
    .ui-datepicker-header .ui-datepicker-prev:focus,
    .ui-datepicker-header .ui-datepicker-next:focus {
      outline: 0 none;
      color: $primary;
    }
    table td a:not(.ui-state-active):not(.ui-state-highlight):hover {
      background-color: $gray1;
    }
    .ui-monthpicker a.ui-monthpicker-month:not(.ui-state-active):hover {
      background-color: $gray1;
    }
  }

  .ui-datepicker-header {
    border-radius: 0;
    background-color: $white;
    padding: $global-spacing;
    color: $gray6;

    .ui-datepicker-prev span:before {
      content: '';
    }
    .ui-datepicker-next span:before {
      content: '';
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
      top: $global-spacing;
      transition: color 0.2s;
      cursor: pointer;
      color: $gray2;
    }
    .ui-datepicker-title {
      margin: 0;
      line-height: $base-line-height;

      select {
        margin-top: -$global-spacing;
        margin-bottom: 0;
      }
    }
  }

  table {
    margin: $global-spacing 0 0 0;
    font-size: $base-font-size;

    th {
      padding: $input-padding;
    }

    td {
      padding: $input-padding;

      & > a,
      & > span {
        display: block;
        border-radius: $button-border-radius;
        padding: $input-padding;
        text-align: center;
        color: $gray6;

        &.ui-state-disabled {
          color: $input-border-color-disabled;
        }
      }

      & > a {
        cursor: pointer;

        &.ui-state-active {
          background-color: $primary;
          color: $white;
        }

        &.ui-state-highlight {
          background-color: $gray1;
          color: $gray6;
        }
      }

      &.ui-datepicker-today a {
        background-color: $gray1;
        color: $gray6;
      }
    }
  }

  .ui-datepicker-buttonbar {
    border-top: 1px solid $gray1;
  }
  .ui-timepicker {
    border: 0 none;
    border-top: 1px solid $gray1;
    padding: $input-padding;
    a {
      color: $gray6;
      font-size: $base-font-size;
      &:hover {
        color: $primary;
      }
    }

    span {
      font-size: $base-font-size;
    }
  }
  .ui-monthpicker .ui-monthpicker-month {
    color: $gray6;
    &.ui-state-active {
      background-color: $primary;
      color: $white;
    }
  }

  &.ui-datepicker-timeonly {
    padding: 0;
  }
  &.ui-datepicker-timeonly .ui-timepicker {
    border-top: 0 none;
  }
  &.ui-datepicker-multiple-month .ui-datepicker-group {
    border-right: 1px solid $gray1;
    padding: 0 $global-spacing;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: 0 none;
      padding-right: 0;
    }
  }

  .ui-calendar-w-btn .ui-inputtext {
    border-right: 0 none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:enabled:hover:not(.ui-state-error),
    &:enabled:focus:not(.ui-state-error) {
      border-right: 0 none;
    }
  }
  .ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: $button-min-width;
  }
}
