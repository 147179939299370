.om-expanded {
  overflow: visible;

  // [dont use outside angularjs - deprecated] somtimes we expand into a long content block and we don't want to restrict the height.
  &.-no-max-height {
    max-height: none;
  }

  .-section + & {
    @extend %banner;
    margin: 0;
    border-radius: 0;
    background: $gray1;
  }
}

.om-collapse-toggle {
  cursor: pointer;

  om-collapse.-disabled &,
  .om-collapse.-disabled & {
    cursor: auto;
  }

  &.om-collapse-expanded {
    margin-bottom: 0;
    background-color: $collapse-toggle-background-color;
  }

  &.-bordered {
    display: block;
    border-top: 2px solid $collapse-toggle-border-color;
    padding-top: $global-spacing * 1.5;
    padding-bottom: $global-spacing / 2;
  }

  &.-separator {
    @extend %bold-type;
    display: block;
    background-color: $collapse-separator-background-color;
    padding: $global-spacing;
    color: $primary;
  }
}
