.om-section {
  // Workaround for Chrome's redraw issues on high
  // DPI screens. Remove this hack whenever Google
  // fixes these issues (> v41.0.2272.76).
  -webkit-transform: translate3d(0, 0, 0); // sass-lint:disable-line no-vendor-prefixes
  margin-right: $layout-gutter-width;
  margin-bottom: $global-spacing;
  margin-left: $layout-gutter-width;
  overflow-x: hidden;

  &[scrollable] {
    overflow: auto;
  }
}

.om-section:first-child {
  margin-left: $global-spacing * 2;
}

.om-section:last-child {
  margin-right: $global-spacing * 2;
}
