// Data Table
//
// This component allows you to arrange data in a tabular format in conjunction with `om-flex` and `om-layout-align` helpers.
//
// Markup:
// <div ng-modules="templates, onelifeUi">
//   <div class="data-table">
//     <h4 class="heading">
//       <span om-flex="15">Date</span>
//       <span om-flex="65">Description</span>
//       <span om-flex>Status</span>
//     </h4>
//     <div class="row">
//       <span om-flex="15">04/05/2018</span>
//       <span om-flex="65">
//         Advil 600mg tabs. This cell will only take up 65% space.
//       </span>
//       <span om-flex>
//         <span class="om-label capitalize">Sent</span>
//       </span>
//     </div>
//     <div class="row"
//          om-layout-align="center">
//       No prescriptions written. This is an example of a row taking full width with centered content.
//     </div>
//     <div class="row -critical">
//       This is an example of an critical row
//     </div>
//     <div class="row -important">
//       This is an example of an important row
//     </div>
//     <om-collapse>
//       <om-collapse-toggle class="row -clickable">
//         <div om-flex>This row is</div>
//         <div om-flex="35">is clickable</div>
//       </om-collapse-toggle>
//       <om-expanded class="row">Expanded content</om-expanded>
//     </om-collapse>
//   </div>
// <br/>
// <h3>Example of a nested table</h3>
// <br/>
// <div class="data-table">
//   <h4 class="heading">
//     <span om-flex>Parent Table</span>
//   </h4>
//   <om-collapse class="row -clickable">
//     <om-collapse-toggle om-layout
//                         om-layout-fill>
//       <span om-collapsed>Clicking this row to reveal the child table.</span>
//       <span om-expanded>Click this row to hide it again.</span>
//     </om-collapse-toggle>
//     <om-expanded om-flex class="om-animate">
//       <div class="data-table"
//            om-layout-fill
//            om-flex>
//         <h4 class="heading">Child Table</h4>
//         <div class="row">Child Table Row</div>
//       </div>
//     </om-expanded>
//   </om-collapse>
// </div>
//
// Styleguide 3.14

.data-table {
  .heading {
    @extend %table-row;
    @extend %h4;
    border-top: $table-border;
    border-bottom: 0;
    background-color: $table-heading-background-color;
    text-transform: uppercase;
    line-height: $base-line-height;
    color: $table-heading-font-color;

    &[om-collapse-toggle]:hover {
      background-color: $table-clickable-heading-background-color;
    }
  }

  .row {
    @extend %table-row;
    background-color: $table-row-background-color;

    &.-important {
      background-color: $red1;
    }

    &.-critical {
      background-color: $red2;
    }

    &:only-child {
      border-top: $table-border;
    }

    &.-clickable {
      @extend %selectable-item;
    }

    &.-vertical-padding {
      padding-top: $global-spacing * 3;
      padding-bottom: $global-spacing * 3;
    }

    & .om-icon {
      width: 24px;
    }
  }

  .data-table {
    margin: $global-spacing 0;

    .heading {
      background-color: $nested-table-heading-background-color;
    }

    .row {
      background-color: $nested-table-row-background-color;
    }
  }
}
