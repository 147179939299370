om-letter,
omg-letter {
  .letterhead-address {
    text-align: center;
    color: $gray4;
  }

  .signature {
    padding-bottom: $global-spacing * 4;
    padding-left: $global-spacing * 2;
    color: $gray4;
  }

  .loading-pdf {
    padding-top: 44px;
    height: 174px;
    text-align: center;
    font-style: italic;

    .fa-lg {
      font-size: 1.8em;
    }

    p {
      padding-top: 22px;
    }
  }
}
