.printed-note {
  p {
    margin-bottom: 1em;
  }

  th,
  td {
    text-align: left;
  }

  .patient-demographics {
    overflow: hidden;

    table {
      float: left;
      margin-right: $global-spacing;
      width: 30%;
    }

    th,
    td {
      border: none;
    }
  }

  .printed-note-body {
    clear: both;
    margin-top: 10px;
    width: 1000px;

    table {
      width: 500px;
    }

    hr {
      display: block;
      margin: 10px 0;
      border-bottom: 1px solid black;
      background: transparent;
      padding: 0;
      width: 100%;
    }
  }
}
