.om-inline-list {
  display: flex;

  &.-divided {

    li + li {

      &::before {
        padding: 0 $global-spacing;
        content: '|';
      }
    }
  }
}

