.padding-half {
  @extend %padding-half;
}

.padding-half-horizontal {
  @extend %padding-half-horizontal;
}

.padding-half-vertical {
  @extend %padding-half-vertical;
}

.padding-normal {
  @extend %padding-normal;
}

.padding-normal-horizontal {
  @extend %padding-normal-horizontal;
}

.padding-normal-vertical {
  @extend %padding-normal-vertical;
}

.border {
  border: 1px solid $gray2;
}

.border-top {
  border-top: 1px solid $gray2;
}

.border-bottom {
  border-bottom: 1px solid $gray2;
}

.border-left {
  border-left: 1px solid $gray2;
}

.border-right {
  border-right: 1px solid $gray2;
}

.pull-right {
  clear: right;
  margin-left: auto;
}

.block {
  display: block;
}

.inline {
  display: inline-block;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}
@each $direction in top, bottom, left, right, all {
  %flush-#{$direction} {
    @include flush-margins($direction);
  }
}

[om-layout] {
  @extend %display-flex;
}
[om-layout-inline] {
  @extend %display-inline-flex;
}

[om-layout='vertical'] {
  flex-direction: column;

  &[om-layout-gutter] > * {
    margin-bottom: $layout-gutter-width;
  }

  &[om-layout-gutter-large] > * {
    margin-bottom: $large-layout-gutter-width;
  }

  &[om-layout-gutter-mini] > * {
    margin-bottom: $mini-layout-gutter-width;
  }
}

[om-layout-padding],
[om-layout][om-layout-padding] > [flex] {
  padding: $layout-gutter-width / 2;
}

[om-layout-padding] + [om-layout-padding] {
  margin-top: -($layout-gutter-width / 2);
  padding-top: 0;
}

:not([om-layout='vertical']) {
  &[om-layout-gutter] > * {
    margin-right: $layout-gutter-width;
  }

  &[om-layout-gutter-large] > * {
    margin-right: $large-layout-gutter-width;
  }

  &[om-layout-gutter-mini] > * {
    margin-right: $mini-layout-gutter-width;
  }
}

[om-layout-gutter] > *,
[om-layout-gutter-large] > *,
[om-layout-gutter-mini] > * {
  &:last-child {
    margin: 0;
  }
}

[om-layout-wrap] {
  flex-wrap: wrap;
}

[om-layout-fill] {
  margin: 0;
  width: 100%;
  min-height: 100%;
}

[om-flex-shrink] {
  flex: 0 1 auto;
}

// Arrange on the Main Axis
// center, start, end, space-between, space-around
// flex-start is the default for justify-content
// ------------------------------

// Main Axis Center
[om-layout-align='center'],
[om-layout-align='center center'],
[om-layout-align='center start'],
[om-layout-align='center end'] {
  justify-content: center;
}

// Main Axis End
[om-layout-align='end'],
[om-layout-align='end center'],
[om-layout-align='end start'],
[om-layout-align='end end'] {
  justify-content: flex-end;
}

// Main Axis Space Around
[om-layout-align='space-around'],
[om-layout-align='space-around center'],
[om-layout-align='space-around start'],
[om-layout-align='space-around end'] {
  justify-content: space-around;
}

// Main Axis Space Between
[om-layout-align='space-between'],
[om-layout-align='space-between center'],
[om-layout-align='space-between start'],
[om-layout-align='space-between end'] {
  justify-content: space-between;
}

// Arrange on the Cross Axis
// center, start, end
// stretch is the default for align-items
// ------------------------------

// Cross Axis Center
[om-layout-align='center center'],
[om-layout-align='start center'],
[om-layout-align='end center'],
[om-layout-align='space-between center'],
[om-layout-align='space-around center'] {
  align-items: center;
}

// Cross Axis Start
[om-layout-align='center start'],
[om-layout-align='start start'],
[om-layout-align='end start'],
[om-layout-align='space-between start'],
[om-layout-align='space-around start'] {
  align-items: flex-start;
}

// Cross Axis End
[om-layout-align='center end'],
[om-layout-align='start end'],
[om-layout-align='end end'],
[om-layout-align='space-between end'],
[om-layout-align='space-around end'] {
  align-items: flex-end;
}

[om-layout-align='baseline'] {
  align-items: baseline;
}

// layout = Default
// ------------------------------
@include layout-presets(om-flex);
@include layout-offset-presets(om-offset);
@include layout-order-presets(om-layout-order);
