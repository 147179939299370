$blockquote-border-color: $gray3;
$blockquote-color: $gray4;
$blockquote-spacing: ($global-spacing * 2);

%paragraph-margin-bottom {
  blockquote,
  ol,
  ul,
  p {
    &:not(:last-child) {
      margin-bottom: $paragraph-margin-bottom;
    }
  }
}

%reset-text-transform {
  a {
    text-transform: initial;
  }
}

%marked {
  @extend %paragraph-margin-bottom;
  @extend %reset-text-transform;

  ol,
  ul {
    padding-left: rem-calc(40px);
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }

  // Formatting of replies is sketchy.
  // Often a line of the reply gets put on the next line, so it ends up in a p tag instead of a blockquote.
  // Assume that anything following a blockquote is also part of the reply.
  blockquote,
  blockquote ~ * {
    margin-left: ($blockquote-spacing * 2);
    border-left: 1px solid $blockquote-border-color;
    padding-left: ($blockquote-spacing * 2);
    color: $blockquote-color;
  }
}

.marked,
[marked] {
  @extend %marked;
}
