// Links
//
//
// .-critical       - anchor for a dangerous action
// .-plain-text     - prevents the text from being capitalized. Use with links that have units of measurement in them.
//
// Markup:
// <a href="https://onemedical.com/" class="om-link {{modifier_class}}">OneMedical, 10 mL</a>
//
// Styleguide 2.3

a {
  @extend %anchor;
  color: $anchor-font-color;

  img {
    border: 0;
  }
}

.om-link {
  @extend %om-link;

  &.-critical {
    @extend %om-critical-link;
  }
}
