*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: $base-font-size;
}

body {
  background-color: $body-background-color;
  min-width: $body-width;
  color: $body-font-color;
  overflow-y: hidden;
}

main {
  height: calc(100% - #{$nav-height});
}

a {
  &:hover,
  &:focus {
    cursor: pointer;
  }

  img {
    border: 0;
  }
}

.pre {
  white-space: pre;
}

// sass-lint:disable no-vendor-prefixes
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
// sass-lint:enable no-vendor-prefixes

th,
td {
  border: 1px solid $gray6;
  padding: $global-spacing / 4 $global-spacing / 2;
}

th {
  @extend %bold-type;
}

td {
  text-align: right;
}
