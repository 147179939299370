@import 'variables';
@import 'shadows';

/**
 ** Extensions
 **/

// Typography
%base-typography {
  line-height: $base-line-height;
  font-family: $body-font-family;
  font-size: $base-font-size;
  font-weight: $body-font-weight;
  font-style: $body-font-style;
}

%bold-type {
  font-weight: $font-weight-semibold;
}

%caption-text {
  color: $gray4;
}

%plain-text {
  text-transform: none;
}

%base-heading-type {
  @extend %bold-type;
  text-rendering: $header-text-rendering;
  font-family: inherit;
}

%h3 {
  line-height: $h3-line-height;
  font-size: $h3-font-size;
}

%h4 {
  text-transform: uppercase;
  line-height: $h3-line-height;
  letter-spacing: rem-calc(0.2);
  font-size: $h4-font-size;
}

%h4-section {
  background-color: $header-background-color-contrasted;
  padding: $section-title-padding;
}

%emphasized-text {
  line-height: inherit;
  font-family: inherit;
  font-style: italic;
}

%text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%wrapped-text {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

// input styles
%focused-input-style {
  outline: 0;
  border-width: $input-border-width;
  border-color: $input-border-color-focus;
}

%invalid-input-style {
  transition: border-color linear 0.3s;
  border-width: $input-border-width;
  border-color: $input-border-color-invalid;
}

%large-icon {
  width: $large-icon-width;
  height: $large-icon-height;
}

// Badges (e.g. pending renewal tab count)
%om-badge-default {
  display: inline-block;
  margin: 0 $badge-padding / 2;
  border: 0;
  border-radius: $badge-border-radius;
  vertical-align: middle;
  text-align: center;
  line-height: $badge-line-height;
  white-space: nowrap;
  color: $badge-base-font-color;
}

%warning-badge {
  background-color: $badge-warning-background-color;
  text-shadow: $badge-warning-shadow-color;
  color: $white;
}

%muted-badge {
  background-color: $badge-muted-color;
}

%success-badge {
  background-color: $badge-success-color;
}

%banner {
  margin: $global-spacing 0;
  border-radius: 3px;
  padding: calc(#{$global-spacing} * 2);
  color: $banner-text;
  font-weight: $font-weight-normal;
}

// Data Tables
%table-row {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-wrap: wrap;
  border-bottom: $table-border;
  padding: $table-row-padding;
  width: 100%;
  border-collapse: collapse;
  font-family: inherit;
}

// Dropdowns
%dropdown-item {
  @extend %base-typography;
  cursor: pointer;
  padding: $button-padding ($button-padding * 2);
  min-width: $button-min-width;
  text-align: unset;
  white-space: nowrap;
  font-family: inherit;

  // above .active so that .active will take precendence
  &.selected {
    background-color: $gray1;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: $blue1;
  }

  &:active {
    background-color: darken($blue1, 30%);
  }

  &.-disabled {
    cursor: not-allowed;
    color: $input-border-color-disabled;
  }
}

%disabled-dropdown-item {
  @extend %emphasized-text;
  cursor: not-allowed;
  padding: $button-padding ($button-padding * 2);
  font-weight: $font-weight-normal;
}

%dropdown-body {
  position: absolute;
  top: 100%;
  left: 0;
  float: left;
  z-index: $zindex-dropdown;
  margin-top: $dropdown-distance-from-trigger;
  border-radius: $button-border-radius;
  border-color: transparent;
  box-shadow: shadow-style(focused, black-shadow);
  background-color: $white;
  padding: 0;
  list-style: none;
  color: $body-font-color;
}

%dropdown-toggle-button {
  &:hover:not(:disabled):not(.-processing),
  &:focus:not(:disabled):not(.-processing) {
    box-shadow: none;
  }
  &[uib-dropdown-toggle] {
    position: relative;
    border-left: 1px solid $white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: $large-icon-width;
  }
}

%title-attribute-caption {
  position: absolute;
  right: 0;
  bottom: calc(#{$global-spacing/2});
  left: 0;
  background: rgba($primary, 0.5);
  padding: $global-spacing;
  text-align: center;
  color: $white;
  content: attr(title);
}

%om-header {
  @extend %bold-type;
  background-color: $header-background-color;
  padding: $om-item-padding;
  color: $header-font-color;

  * {
    margin-bottom: 0;
  }

  .fa {
    color: $header-font-color;
  }

  input::placeholder {
    color: rgba($header-font-color, 0.6);
  }
}
%hidden {
  position: absolute !important; // sass-lint:disable-line no-important
  width: 1px;
  height: 1px;
  clip-path: polygon(1px 1px, 1px 1px, 1px 1px, 1px 1px);
}

// om-items
%rounded-frame {
  border: 1px solid $item-border-color;
  border-radius: $base-font-size / 3;
}

%selectable-item {
  cursor: pointer;

  &:hover {
    background-color: $item-selectable-hover-background-color;
  }

  &.-selected {
    background-color: darken($item-selectable-hover-background-color, 5);
  }
}

// Layout
%containing-block {
  // allow the element to have absolutely positioned children
  position: relative;
}

%padding-half {
  padding: $global-spacing;
}

%padding-half-horizontal {
  padding: 0 $global-spacing;
}

%padding-half-vertical {
  padding: $global-spacing 0;
}

%padding-normal {
  padding: $normal-padding;
}

%padding-normal-horizontal {
  padding: 0 ($global-spacing * 2);
}

%padding-normal-vertical {
  padding: ($global-spacing * 2) 0;
}

%display-flex {
  display: flex !important; // sass-lint:disable-line no-important
  justify-content: flex-start;
  box-sizing: border-box;
}
%display-inline-flex {
  @extend %display-flex;
  display: inline-flex !important; // sass-lint:disable-line no-important
}

// Links
%anchor {
  background-color: inherit;
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: $anchor-text-decoration;
  font-family: inherit;

  &:hover,
  &:focus {
    @if $anchor-text-decoration-hover != $anchor-text-decoration {
      text-decoration: $anchor-text-decoration-hover;
    }
  }

  &.-plain-text {
    @extend %plain-text;
  }
}

%om-link {
  @extend %anchor;
  color: $anchor-font-color;
  &:hover,
  &:focus {
    &:not(:disabled) {
      color: $anchor-font-color-hover;
    }
  }

  &:disabled,
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
    text-decoration: none;
  }
}

%om-critical-link {
  @extend %om-link;
  color: $critical;

  &:hover,
  &:focus {
    &:not(:disabled) {
      color: $critical;
    }
  }
}

%base-nav-item {
  display: flex;
  align-items: center;
  padding-right: $nav-padding;
  padding-left: $nav-padding;
  height: $nav-height;
  color: $nav-font-color;
  font-size: $nav-font-size;
}

%hidden-broken-placeholder-hack {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: ' ';
}

%label {
  @extend %bold-type;
  vertical-align: baseline;
  text-transform: capitalize;
  color: $form-label-color;
  font-family: inherit;
  font-weight: $font-weight-semibold;

  > input,
  + input,
  > textarea,
  > .om-ui-select,
  + textarea,
  + om-search,
  + ui-select,
  &.checkbox-label {
    font-weight: $font-weight-normal;
  }

  &.plain-text {
    @extend %plain-text;
  }
}

%profile-image-bubble {
  display: inline-block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

// Tabs
%scrollable-area {
  max-height: $page-header-offset-height;
  overflow: auto;
}

%scrollable-area-with-subheading {
  @extend %scrollable-area;
  max-height: $tabs-scrollable-with-subheading-height;
}

%scrollable-area-with-subheading-and-action-bar {
  @extend %scrollable-area;
  max-height: $tabs-scrollable-with-subheading-and-action-bar-height;
}

%expanded-content {
  background-color: $collapse-toggle-background-color;
}

// Animation
%easing-animation {
  transition: all $animation-duration ease-in-out;
}

/**
 ** Mixins
 **/
@mixin banner-variant($background-color, $border-color, $hover-color) {
  background-color: $background-color;
  .section {
    border-top-color: $border-color;
  }
  &.clickable:hover {
    background-color: $hover-color;
  }
}

@mixin button-opacity($background-color, $color, $amount) {
  $disabled-font-color: $color;
  @if ($color != $white) {
    $disabled-font-color: transparentize($color, $amount);
  }
  background-color: transparentize($background-color, $amount);
  color: $disabled-font-color;
}

// Buttons
@mixin button-style($background-color, $border-color, $font-color) {
  @extend %base-typography;
  @extend %bold-type;
  border-width: $button-border-width;
  border-style: solid;
  border-radius: $button-border-radius;
  border-color: $border-color;
  background-color: $background-color;
  cursor: pointer;
  padding: 0 $button-padding;
  vertical-align: top;
  text-align: center;
  text-transform: capitalize;
  color: $font-color;
  font-family: inherit;
  font-weight: $font-weight-semibold;

  &:active {
    background-color: darken($background-color, 15%);
  }

  &:disabled {
    @include button-opacity($background-color, $font-color, 0.4);
    cursor: not-allowed;
  }

  &.-processing {
    @include button-opacity($background-color, $font-color, 0.7);
    cursor: not-allowed;
  }

  &:hover:not(:disabled):not(.-processing),
  &:focus:not(:disabled):not(.-processing) {
    box-shadow: shadow-style(focused, black-shadow);
    background-color: darken($background-color, 5%);
  }

  .om-icon {
    background-color: $font-color;
  }
}

%primary-button-style {
  @include button-style($button-primary-background-color, transparent, $button-primary-color);
}

%secondary-button-style {
  @include button-style($button-secondary-background-color, transparent, $button-secondary-color);
}

%tertiary-button-style {
  @include button-style($button-tertiary-background-color, transparent, $button-tertiary-color);
}

%critical-button-style {
  @include button-style($button-critical-background-color, transparent, $button-critical-color);
}

%flat-button-style {
  @include button-style($white, transparent, $primary);
  background-color: inherit;
}

@mixin input-style {
  @extend %base-typography;
  transition: border-color linear 0.1s;
  margin-bottom: $global-spacing;
  border-width: $input-border-width;
  border-style: solid;
  border-radius: $input-border-radius;
  border-color: $input-border-color;
  background-color: $input-background-color;
  color: $input-font-color;

  &:active,
  &:focus {
    @extend %focused-input-style;
  }

  &::placeholder {
    @extend %emphasized-text;
    color: $gray3;
  }

  &.-small {
    width: $small-input-fixed-width;
  }

  &[type='text'],
  &[type='password'],
  &[type='number'],
  &[type='date'] {
    padding: $input-padding;
  }

  &[type='number'] {
    margin-bottom: 0;

    // sass-lint:disable no-vendor-prefixes
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // sass-lint:enable no-vendor-prefixes
  }

  &.-with-error,
  &.-with-updated {
    margin-bottom: 0;
  }

  &.-with-warning {
    border: solid $input-border-width $warning-message-color;
  }
}

@mixin flush-margins($direction) {
  @if $direction == all {
    margin: 0;
  } @else {
    margin-#{$direction}: 0;
  }
}

@mixin layout-preset($value) {
  flex: 0 0 $value;
  max-width: $value;
}

@mixin layout-offset-presets($attr) {
  // (1-19) * 5 = 5-95%
  @for $i from 1 through 19 {
    [#{$attr}='#{ $i * 5 }'] {
      margin-left: #{$i * 5 + '%'};
    }
  }

  [#{$attr}='33'],
  [#{$attr}='34'] {
    margin-left: 33.33%;
  }

  [#{$attr}='66'],
  [#{$attr}='67'] {
    margin-left: 66.66%;
  }
}

@mixin layout-order-preset($attr, $n) {
  [#{$attr}='#{$n}'] {
    order: $n;
  }
}

@mixin layout-order-presets($attr) {
  @for $i from 1 through 6 {
    @include layout-order-preset($attr, $i);
  }
}

@mixin layout-presets($attr) {
  [#{$attr}] {
    flex: 1;
  }

  // (1-19) * 5 = 5-95%
  @for $i from 1 through 19 {
    [#{$attr}='#{ $i * 5 }'] {
      @include layout-preset(#{$i * 5 + '%'});
    }
  }

  [#{$attr}='33'],
  [#{$attr}='34'] {
    @include layout-preset(33.33%);
  }

  [#{$attr}='66'],
  [#{$attr}='67'] {
    @include layout-preset(66.66%);
  }

  [#{$attr}='auto'] {
    @include layout-preset(auto);
  }
}

@mixin top-border($border-width, $border-style) {
  border-top: $border-width $border-style $collapse-toggle-border-color;
}
