body {
  @extend %base-typography;
  .inter {
    font-family: Inter, Tahoma, Geneva, Verdana, sans-serif;

    &.optimal {
      h1 {
        line-height: 34px;
        letter-spacing: -0.019em;
        font-size: 24px;
      }

      h2 {
        line-height: 28px;
        letter-spacing: -0.017em;
        font-size: 20px;
      }

      h3 {
        line-height: 24px;
        letter-spacing: -0.013em;
        font-size: 17px;
      }

      h4 {
        line-height: 20px;
        letter-spacing: -0.006em;
        font-size: 14px;
      }

      .om-input {
        line-height: 20px;
        letter-spacing: -0.006em;
        font-family: inherit;
        font-size: 14px;
      }
    }
  }
}

h1,
h2,
h3,
h4 {
  @extend %base-heading-type;
  font-family: inherit;

  a {
    text-transform: inherit;
    font-family: inherit;
    font-size: inherit;
  }

  &.-checked {
    &::before {
      margin-left: -$global-spacing;
      padding-left: $global-spacing / 2;
      color: $tertiary;
      font-family: FontAwesome;
      content: '\f00c ';
    }
  }
}

h1 {
  margin: $h1-margin 0;
  line-height: $h1-line-height;
  font-size: $h1-font-size;
}

h2 {
  margin: $h2-margin 0;
  line-height: $h2-line-height;
  font-size: $h2-font-size;
}

h3 {
  @extend %h3;

  &.-large {
    font-weight: $font-weight-normal;
  }

  &.-section-title {
    @extend %bold-type;
    margin: $hr-large-spacing 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

h4 {
  @extend %h4;
  &.-section {
    @extend %h4-section;
  }
}

// Body / Paragraph Text
//
// Our regular body font for most of our text. Try not to make this go wider than 3 columns/516 px, this makes it really difficult to read.
//
// .caption	              - a brief explanation appended a primary description in a subdued color.
// .critical-message	    - critical colored text
//
// markup:
// Open Sans font-size 15px, line-height 24px, regular font-weight.
// <p>Cronut beard distillery 8-bit flannel salvia keffiyeh seitan vape raw denim knausgaard hammock. Listicle leggings semiotics, you probably haven't heard of them lomo williamsburg literally hella farm-to-table cliche PBR&B lo-fi air plant.</p>
// <p class="{{modifier_class}}">XOXO etsy direct trade, blue bottle gluten-free keffiyeh mixtape microdosing.</p>
//
// Styleguide 2.2

p {
  text-rendering: $paragraph-text-rendering;
  line-height: $paragraph-line-height;
  white-space: $paragraph-white-space;
  font-family: inherit;
  font-size: $paragraph-font-size;
  font-weight: $paragraph-font-weight;
}

pre {
  font-family: $pre-font-family;
  font-size: $pre-font-size;
}

// Emphasized
//
// markup:
// <i>Irony food truck stumptown austin keytar fanny pack chia pug DIY vegan occupy selvage dreamcatcher tumblr polaroid.</i>
// <em>La croix direct trade wayfarers ramps, twee wolf venmo tilde tacos cronut trust fund gochujang. </em>
// <div class="emphasized">Chicharrones austin ugh sartorial unicorn vexillologist. Taiyaki ugh typewriter, normcore godard tote bag mlkshk keytar raclette actually chartreuse pour-over meggings pop-up.</div>
//
// Styleguide 2.2.4
em,
i,
.emphasized {
  @extend %emphasized-text;
}

strong,
b {
  @extend %bold-type;
  font-size: inherit;
}

// Lists
dl {
  line-height: $list-line-height;
  font-family: $list-font-family;
  font-size: $list-font-size;
  list-style-position: $list-style-position;

  dt {
    @extend %bold-type;
  }

  dd {
    padding-left: $global-spacing;
  }
}

ul {
  li {
    line-height: $list-line-height;
  }
}

// Separators

hr {
  margin-top: $hr-standard-spacing;
  margin-bottom: $hr-standard-spacing;
  border: 0;
  background-color: $hr-color;
  width: 100%;
  height: 1px;

  &.-dotted {
    border: 0;
    border-top: 1px dotted $hr-color;
    background-color: $white;
    height: 0;
  }

  &.-spaced {
    margin: $hr-large-spacing 0;
  }

  &.-thick {
    height: 3px;
  }

  &.-flush {
    @extend %flush-all;
  }
}

.caption {
  @extend %caption-text;
}

.critical-message {
  color: $critical;
}

.success-message {
  color: $green5;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.highlighted {
  background-color: $yellow3;
  font-weight: $font-weight-semibold;
}

.-truncated {
  @extend %text-truncate;
}

.-wrapped-text {
  @extend %wrapped-text;
}

.paragraph-with-html p {
  white-space: normal;
}
