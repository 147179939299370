.om-form {
  .om-dymo {
    .om-dymo-label {
      margin-right: $global-spacing;
      text-transform: none;
      font-weight: $font-weight-normal;
    }
    .om-dymo-button {
      min-width: 0;
    }
  }
}
