// NOTE: This file is temporary helpers for the migration procese. It
// can be safely removed when the upgrade to Angular 7 is completed.

/* Highlight angular 7 components */
body.hightlight-angular {
  [ng-version]:not(omg-root) {
    border-width: 2px;
    border-style: dashed;
    border-color: red;
  }
}
