// Banners
//
// Provide contextual feedback messages for actions with the handful of banners.
//
// Markup:
// <div class="banner">
//  Hola! This is a general banner with a <a href="http://onemedical.com">link</a>.
//  <ul>
//    <li>If 2+ elements in a UL in a Banner, LIs will be bulleted</li>
//    <li>element 2</li>
//  </ul>
//  <ul>
//    <li>Bullets won't show up in a UL if there's only one LI</li>
//  </ul>
// </div>
// <div class="banner -info">
//  This is extra important
// </div>
// <div class="banner -info">
//  <div class="header">Important Title</div>
//  Some kind of subtitle
// </div>
// <div class="banner -warning">
//  This is sorta bad, watch out!
// </div>
// <div class="banner -success">
//  Something good happened!
// </div>
// <div class="banner -error">
//  This is error!
// </div>
// <div class="banner -destructive">
//  Watch out, you're bout to delete something!
// </div>
// <div class="banner">
//  <div class="header">This is a banner with a header and sections.</div>
//  <div class="section">This is the 1st section</div>
//  <div class="section">This is the second section.</div>
// </div>
//
// Styleguide 3.10
.banner {
  @extend %banner;
  background-color: $banner-background;

  .section {
    margin-top: $global-spacing;
    border-top: 1px solid $gray4;
    padding-top: $global-spacing;
  }

  .header {
    @extend %label;
    color: $banner-text;
  }

  &.-flush {
    @extend %flush-all;
  }

  &.-flush-bottom {
    @extend %flush-bottom;
  }

  &.-info {
    border: 1px solid $gray4;
  }

  &.-success {
    @include banner-variant($success-background, $green6, $green4);
  }

  &.-warning {
    @include banner-variant($warning-background, $yellow6, $yellow4);
  }

  &.-error,
  &.-destructive {
    background-color: $error-background;
    color: $error-text;

    .section {
      border-top-color: $red6;
    }

    &.clickable {
      &:hover {
        background-color: $red5;
      }
    }

    a,
    a:hover {
      color: $error-text;
    }

    a {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }
  }

  ul {
    li:not(:only-child) {
      margin-left: calc(#{$global-spacing} * 4);
      list-style-type: disc;
    }
  }

  .fa-circle {
    vertical-align: middle;
    font-size: 0.25em;
  }
}

.toast-banner {
  @extend %banner;
  margin-right: auto;
  margin-left: auto;
  background-color: $primary;
  width: 30%;

  a {
    @extend %anchor;
  }

  &.toast-success {
    background-color: $success-background;

    > div {
      display: inline-block;
    }
  }

  &.toast-warning {
    background-color: $warning-background;

    > div {
      display: inline-block;
    }
  }
}
