om-problems {
  [marked] {
    @extend %marked;
  }

  .-subform > .om-form {
    margin-top: $global-spacing;
  }
}

.auto-create-toggle {
  border-top: 1px solid $gray3;
}

.auto-create-toggle input {
  margin-left: 5px;
}

.auto-create-message {
  @extend %bold-type;
  padding-top: $global-spacing * 2;
}

.auto-create-description {
  padding-left: 22px;
  color: $gray3;
}
