$typeahead-option-height: $base-line-height + ($button-padding * 2);

.om-typeahead,
[om-typeahead] {
  position: relative;

  &.-margined [uib-typeahead] {
    margin-bottom: $global-spacing;
  }

  [uib-typeahead] {
    margin-bottom: 0;
    width: 100%;
  }

  &.dropdown-menu,
  .dropdown-menu {
    @extend %dropdown-body;
    position: absolute;
    z-index: $zindex-typeahead;
    width: 100%;
    overflow: auto;
  }

  .option {
    @extend %dropdown-item;

    a {
      @extend %base-typography;
      display: block;
      text-transform: none;
      text-decoration: none;
      white-space: nowrap;
      color: $body-font-color;

      &.caption {
        @extend %caption-text;
      }
    }
  }

  .disabled-option {
    @extend %disabled-dropdown-item;
  }

  &.om-typeahead-dropup,
  &[om-typeahead='dropup'] {
    .dropdown-menu {
      top: auto !important; // sass-lint:disable-line no-important
      bottom: 100%;
      margin-bottom: $dropdown-distance-from-trigger;
    }
  }

  @mixin om-typeahead-size($n) {
    &[om-typeahead-size='#{$n}'] {
      .dropdown-menu {
        max-height: $typeahead-option-height * $n;
      }
    }
  }

  @include om-typeahead-size(8);
}
